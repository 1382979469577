import isPlainObject from 'lodash/isPlainObject';
import mapKeys from 'lodash/mapKeys';
import mapValues from 'lodash/mapValues';

// Recursively update all keys in an object.
// The function fn is called with each key and should return the new key.
// updateKeys(toPascalCase) would transform { 'fooBar': 'baz' } to { 'FooBar': 'baz' }
export function mapKeysDeep<T extends object>(obj: T, fn: (key: string) => string): T {
  // This helper function is used to work around TypeScript type signature issues.
  function lodashUpdater(obj: object, cb: (value: unknown, key: string) => string): unknown {
    if (Array.isArray(obj)) {
      return obj.map((item) => lodashUpdater(item, cb));
    }
    if (isPlainObject(obj)) {
      return mapValues(mapKeys(obj, cb), (value) => lodashUpdater(value, cb));
    }
    return obj;
  }

  return lodashUpdater(obj, (_, k) => fn(k)) as T;
}
