import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import { useQueryRMSSalesChannels } from '@fd/customHooks/useQueryRMSSalesChannels';
import type { SalesChannel } from '@flipdish/orgmanagement';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import EmptyComponent from '@fd/ui/EmptyComponent';
import DynamicAlertBanner from '@fd/ui/molecules/DynamicAlertBanner/DynamicAlertBanner';

import { getTranslate, Translate } from '../../../overrides/react-localize-redux';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { StoreDetails } from '../../Stores/StoreDetails';
import POSChannelSettingsList from './POSChannelSettingsList';
import { getBackToSalesChannelListUrl, getIsFlipdishChannel } from './utils';

const StyledList = styled(List)(() => ({
  padding: 0,
}));

const Content = ({ isLoading, error, salesChannel, translate, baseUrl }) => {
  const salesChannelType = salesChannel?.type || '';
  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <EmptyComponent title="Something_went_wrong" />;
  }

  if (!salesChannel) {
    return <EmptyComponent title="Sales_Channel_Not_Found" noLink />;
  }

  return (
    <PaperContainer fluid>
      <StyledList aria-label={translate('Sales_Channel_Settings') as string}>
        {getIsFlipdishChannel(salesChannelType) ? (
          <StoreDetails linkedSalesChannelId={salesChannel?.hierarchy?.primarySalesChannelId} />
        ) : (
          <POSChannelSettingsList baseUrl={baseUrl} salesChannelType={salesChannelType} />
        )}
      </StyledList>
    </PaperContainer>
  );
};
const SalesChannelSettings = (props: MappedState) => {
  const { currentApp, translate, orgId } = props;
  const history = useHistory();
  const location = useLocation();

  const params = useParams<{ channelId: string; propertyId: string }>();
  const channelId = params.channelId;
  const propertyId = params.propertyId;
  const isOrgApiPropertyId = !!propertyId?.startsWith('p');

  const {
    data: commonData,
    isLoading: isLoadingCommonData,
    error: commonDataError,
  } = useQueryRMSCommonDataHook(currentApp?.AppId || '');

  const orgPropertyId =
    (isOrgApiPropertyId
      ? propertyId
      : commonData?.associations?.orgHierarchy?.properties?.find(
          (property) => property.code === propertyId || property.id === propertyId
        )?.code) || propertyId;

  const getBaseUrl = () => {
    const { pathname } = location;
    const basePath = `/${currentApp?.AppId}/sales-channels`;

    switch (true) {
      case pathname.includes('website'):
        return `${basePath}/website/property/${propertyId}/sales-channel/${channelId}`;
      case pathname.includes('kiosk'):
        return `${basePath}/kiosk/property/${propertyId}/sales-channel/${channelId}`;
      case pathname.includes('properties'):
        return `/${currentApp?.AppId}/properties/${propertyId}/sales-channel/${channelId}`;
      case pathname.includes('marketplaces'):
        return `${basePath}/marketplaces/property/${propertyId}/sales-channel/${channelId}`;
      case pathname.includes('mobile-apps'):
        return `${basePath}/mobile-apps/property/${propertyId}/sales-channel/${channelId}`;
      default:
        return `${basePath}/point-of-sale/property/${propertyId}/sales-channel/${channelId}`;
    }
  };

  const { data, isLoading, error } = useQueryRMSSalesChannels({
    orgId: orgId ?? '',
    propertyId: orgPropertyId ?? '',
  });

  const salesChannel =
    data?.find((salesChannel) => salesChannel?.salesChannelId === channelId) || null;
  const salesChannelName = salesChannel?.name ?? '';

  const primarySalesChannelId = salesChannel?.hierarchy?.primarySalesChannelId;
  const trimmedPathname = `${location.pathname.substring(0, location.pathname.lastIndexOf('/'))}/${primarySalesChannelId}`;

  // @ts-ignore API client doesn't have expanded types for hierarchy
  const primarySalesChannelName = salesChannel?.hierarchy?.primarySalesChannel?.name || '';
  // @ts-ignore API client doesn't have expanded types for hierarchy
  const linkedSalesChannelNames = salesChannel?.hierarchy?.linkedSalesChannels?.map(
    (linkedSalesChannel: SalesChannel) => linkedSalesChannel.name
  );

  return (
    <PageLayout
      documentTitle="Sales_channels"
      toParent={() => {
        history.push(
          getBackToSalesChannelListUrl({
            appId: currentApp?.AppId,
            pathname: location?.pathname,
            propertyId,
          })
        );
      }}
      title={
        <>
          <Translate id="Sales_Channel_Settings" /> {salesChannelName && ` - ${salesChannelName}`}
        </>
      }
    >
      <>
        {primarySalesChannelId && (
          <DynamicAlertBanner
            backgroundColor="blue"
            translatedBannerText={
              translate('This_sales_channel_is_managed_by_the_primary_sales', {
                linkedChannelName: primarySalesChannelName,
              }) as string
            }
            translatedButtonText={
              translate('Go_to_linkedChannelName', {
                linkedChannelName: primarySalesChannelName,
              }) as string
            }
            showButton={true}
            toUrl={trimmedPathname}
          />
        )}
        {linkedSalesChannelNames && (
          <DynamicAlertBanner
            backgroundColor="blue"
            translatedBannerText={
              translate('Primary_Sales_Channel_Configurations_here_will_app', {
                channelNameList: linkedSalesChannelNames?.join(', ') || '',
              }) as string
            }
          />
        )}
        <Content
          isLoading={isLoading || isLoadingCommonData}
          error={error || commonDataError}
          salesChannel={salesChannel}
          translate={translate}
          baseUrl={getBaseUrl()}
        />
      </>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state.locale),
    currentApp: state.currentApp,
    orgId: state.rms?.currentOrg?.orgId,
  };
};

export default connect(mapStateToProps)(SalesChannelSettings);
