import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import PageLayout from '@fd/ui/Layout';

import CreateAndEditProperty from './components/CreateAndEditProperty';
import { getPropertyById } from './properties.services';

const CircularProgressContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

const PropertyGeneralSettingsPage = ({ currentOrg, translate, currentApp }: MappedState) => {
  const orgId = currentOrg?.orgId || '';

  const { propertyId } = useParams<{ propertyId: string }>();

  // only want loading status on initial load don't shout at me Florin :)
  const { data: getPropertyData, isLoading } = useQuery({
    queryKey: ['getPropertyById', orgId, propertyId],
    queryFn: () => getPropertyById(orgId, propertyId),
    enabled: !!propertyId,
  });

  const propertyData = getPropertyData?.data?.data || {};
  return (
    <PageLayout
      title={translate('General_settings')}
      toParent={`/${currentApp?.AppId}/properties/${propertyId}?tab=settings`}
      strictToParent
    >
      <>
        {isLoading ? (
          <CircularProgressContainer>
            <CircularProgress data-fd={'circular-progress-property-general-settings'} />
          </CircularProgressContainer>
        ) : (
          <CreateAndEditProperty propertyId={propertyId} propertyData={propertyData} />
        )}
      </>
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state) => ({
  currentApp: state.currentApp,
  currentOrg: state.rms.currentOrg,
  translate: getTranslate(state.locale),
});

export default connect(mapStateToProps)(PropertyGeneralSettingsPage);
