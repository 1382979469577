import React from 'react';

import { connect } from 'react-redux';
import { compose } from 'recompose';

import SubPageTitle from '../../../layouts/Portal/SubPageTitle';
import { storeSelectors } from '../../../selectors';
import { dateTimeUtils } from '../../../selectors/localeDateTime.selector';

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, { storeId }: Props) => {
  const envTimeZones = storeSelectors.envTimeZones(state, { storeId });

  return {
    envTimeZones,
    dtUtils: dateTimeUtils(state),
  };
};

type Props = { storeId: number };
const Title = compose<Props & MappedState, Props>(connect(mapStateToProps))(() => {
  return (
    <>
      <SubPageTitle translateId="Opening_hour_overrides_title" />
      {/* HIDING Tutorial until work is done to update its api calls etc CLX-1508 */}
      {/* {!!storeId && (
        <TutorialNotifier
          storeId={storeId}
          onboardingItemId={601}
          status={'Completed' as OnboardingItemUpdate}
        />
      )} */}
    </>
  );
});

export default Title;
