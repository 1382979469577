import React from 'react';

import { Translate } from 'react-localize-redux';
import { Route, Switch } from 'react-router-dom';

import LazyComponent from '@fd/ui/LazyComponent';

import { toKebabCase } from '../../../helpers/strings';
import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { Data } from './Reports';

const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));
const ReportList = lazyWithRetry(() => import('./ReportList'));

const ReportRoutes = () => {
  return (
    <ErrorBoundary identifier="rms-reports">
      <Switch>
        {Data.flatMap((group) =>
          group.reports.map((report) => (
            <Route exact key={group.path} path={`/:appId/reports/rms${toKebabCase(report.path)}`}>
              <LazyComponent>
                <RMSPage
                  toParent={'./'}
                  subscription={group.subscription}
                  title={<Translate id={report.title} />}
                  url={`reports${report.path}`}
                  module={`reports${report.path}`}
                  includePropertyIdInUrl
                  allowFranchisorLevel={!!report.allowFranchisorLevel}
                  allowAllProperties={!!report.allowFranchisorLevel}
                />
              </LazyComponent>
            </Route>
          ))
        )}

        {Data.map((group) => (
          <Route key={group.path} path={`/:appId/reports/rms${group.path}`}>
            <LazyComponent>
              <ReportList reportGroup={group} />
            </LazyComponent>
          </Route>
        ))}
      </Switch>
    </ErrorBoundary>
  );
};

export default ReportRoutes;
