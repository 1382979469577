import React from 'react';

import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';

const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));

const SignageRoutes: React.FC<React.PropsWithChildren<RouteComponentProps>> = (
  props: RouteComponentProps
) => {
  const {
    match: { path },
  } = props;
  return (
    <ErrorBoundary identifier="signage">
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                toParent=""
                subscription={RMSSubscriptions.SIGNAGE}
                title={<Translate id="Signage" />}
                url={`modules/display/${RMSModules.SIGNAGE}`}
                module={RMSModules.SIGNAGE}
              />
            </LazyComponent>
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default SignageRoutes;
