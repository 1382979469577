import React from 'react';

import { useAppSelector } from '@fd/customHooks/useAppSelector';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBookOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import List from '@mui/material/List';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';

import ProfileTrackingIcon from '../../../assets/images/bookmark_star.svg';
import InventoryStockIcon from '../../../assets/images/inventory_stock_items.svg';
import { toKebabCase } from '../../../helpers/strings';
import { getTranslate, Translate } from '../../../overrides/react-localize-redux';
import { RMSModules } from '../utils/rmsModules';

const CentralKitchenManagement = () => {
  const translate = useAppSelector((state) => getTranslate(state));
  const currentApp = useAppSelector((state) => state.currentApp);
  const baseUrl = `/${currentApp?.AppId}/add-ons/central-kitchen/configuration`;

  return (
    <PageLayout
      documentTitle="Central_kitchen"
      title={<Translate id="Central_kitchen_management" />}
      toParent={`/${currentApp?.AppId}/add-ons/central-kitchen/`}
      strictToParent
    >
      <PaperContainer fluid>
        <List aria-label={translate('Central_kitchen_management') as string}>
          <ListItemLinkButton
            title="Basic_Configuration"
            subtitle="Name_registration_menu_assignment_email"
            link={`${baseUrl}/setup`}
            iconComponent={<SettingsOutlinedIcon />}
          />
          <ListItemLinkButton
            title="Menus"
            subtitle="Edit_the_central_kitchen_menu"
            link={`${baseUrl}/menus`}
            iconComponent={<MenuBookIcon />}
          />
          <ListItemLinkButton
            title="Stock_Item_Categories"
            subtitle="Manage_stock_for_central_kitchen"
            link={`${baseUrl}/${toKebabCase(RMSModules.STOCK_ITEM_CATEGORIES)}`}
            iconComponent={<CategoryIcon />}
          />
          <ListItemLinkButton
            title="Inventory_Stock_Items"
            subtitle="Manage_list_of_stock_items_for_the_central_kitchen"
            link={`${baseUrl}/${toKebabCase(RMSModules.STOCK_ITEMS)}`}
            iconComponent={<img src={InventoryStockIcon} alt="inventory stock items" />}
          />
          <ListItemLinkButton
            title="Suppliers"
            subtitle="Manage_suppliers_details_for_central_kitchen"
            link={`${baseUrl}/suppliers`}
            iconComponent={<LocalShippingIcon />}
          />
          <ListItemLinkButton
            title="Inventory_Tracking_Profiles"
            subtitle="Manage_inventory_tracking_profiles"
            link={`${baseUrl}/${toKebabCase(RMSModules.TRACKING_PROFILES)}`}
            iconComponent={<img src={ProfileTrackingIcon} alt="inventory tracking profiles" />}
            isLast
          />
        </List>
      </PaperContainer>
    </PageLayout>
  );
};

export default CentralKitchenManagement;
