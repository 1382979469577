import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import LoadingButton from '../../../ui/LoadingButton';
import Tooltip from '../../../ui/Tooltip/Tooltip';
import { type CardData } from './DangerZoneList';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '244px',
    color: '#ff395f',
    letterSpacing: '1.25px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    [theme.breakpoints.down(640)]: { width: '100%' },
    '& :hover': { color: 'rgba(0, 0, 0, 0.38)' },
  },
  tooltip: {
    fontSize: '12px',
    letterSpacing: '0.4px',
    fontWeight: 'normal',
  },
  addPadding: { paddingBottom: '16px', paddingRight: '24px' },
}));

type Props = {
  card: CardData;
  storeName: string;
  isPublished: boolean | undefined;
  handleSubmit: () => Promise<void>;
  tooltipMessage: TranslationId;
  dialogTitle: TranslationId;
  dialogDescription: TranslationId;
  helperText: TranslationId;
};
const UnpublishStoreButton = (props: Props) => {
  const classes = useStyles();

  const {
    card,
    storeName,
    isPublished,
    handleSubmit,
    tooltipMessage,
    dialogTitle,
    dialogDescription,
    helperText,
  } = props;
  const [open, setOpen] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [textField, setTextField] = useState<string>();
  const [isSubmitting, setSubmitting] = useState(false);

  const openDialog = () => {
    setOpen(true);
    setSubmitting(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const _handleSubmit = async () => {
    try {
      if (textField === storeName) {
        setSubmitting(true);
        await handleSubmit();
        setOpen(false);
      } else {
        setValidationError(true);
      }
    } catch (e) {
      setSubmitting(false);
      console.error(e);
    }
  };

  return (
    <>
      <Tooltip
        messageId={tooltipMessage}
        fdKey="tooltip"
        disableHoverListener={isPublished}
        disableTouchListener={isPublished}
      >
        <div>
          <Button
            data-fd={`${card.buttonText}_btn`}
            variant="outlined"
            className={classes.button}
            key={card.buttonText}
            onClick={openDialog}
            disabled={!isPublished}
          >
            <Translate id={card.buttonText} />
          </Button>
        </div>
      </Tooltip>

      <Dialog open={open} onClose={closeDialog} aria-labelledby="Unpublish_Store">
        <DialogTitle id={dialogTitle}>
          <Translate id={dialogTitle} />
        </DialogTitle>
        <DialogContent style={{ maxWidth: '280px' }}>
          <DialogContentText component="div">
            <Typography variant="body1" color="textSecondary">
              <Translate id="If_you_are_sure_you_want_to_unpublish" />{' '}
              <span style={{ fontWeight: 500, fontStyle: 'italic' }}>{storeName}</span>
              {', '}
              <Translate id={dialogDescription} />
            </Typography>
          </DialogContentText>

          <TextField
            variant="standard"
            inputProps={{ 'data-fd': 'unpublish-store-textfield' }}
            autoFocus
            disabled={isSubmitting || !isPublished}
            required
            placeholder={storeName}
            error={validationError}
            margin="none"
            style={{ width: '100%' }}
            onChange={(e) => setTextField(e.target.value)}
            helperText={validationError ? <Translate id={helperText} /> : ''}
          />
        </DialogContent>
        <DialogActions className={classes.addPadding}>
          <Button
            data-fd={`cancel-unpublish-store`}
            onClick={closeDialog}
            color="primary"
            disabled={isSubmitting}
          >
            <Translate id="Cancel" />
          </Button>

          <LoadingButton
            fdKey={`confirm-unpublish-store`}
            onClick={_handleSubmit}
            color="secondary"
            disabled={isSubmitting || !isPublished}
            loading={isSubmitting}
          >
            <Translate id="Unpublish" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UnpublishStoreButton;
