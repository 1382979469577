import React from 'react';

import { DynamicFormField } from '@flipdish/api-client-typescript';
import { type Property } from '@flipdish/orgmanagement';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { TranslateFunction } from 'react-localize-redux';

import { MessageBanner, SkeletonLoader } from '@fd/ui/atoms';
import { DynamicInputFields } from '@fd/ui/molecules/DynamicInputFields/DynamicInputFields';

export type DynamicAddressFormProps = {
  form?: DynamicFormField[];
  loading?: boolean;
  error?: boolean;
  translate: TranslateFunction;
  disabled?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
};

const useStyles = makeStyles((theme: Theme) => ({
  dynamicFieldSkeleton: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  banner: {
    borderRadius: '5px',
    backgroundColor: 'rgb(202, 0, 48)',
  },
  bannerContent: {
    color: 'rgb(255, 255, 255)',
  },
  form: {
    width: '100%',
  },
}));

export const DynamicAddressWithCallback = ({
  form,
  loading = false,
  error = false,
  translate,
  disabled = false,
}: DynamicAddressFormProps) => {
  const classes = useStyles();
  const formikContext = useFormikContext<Property>();
  const renderFormBody = () => {
    if (error) {
      return (
        <MessageBanner
          titleClassName={classes.bannerContent}
          className={classes.banner}
          title={translate('User_creation_failed') as string}
          icon={<ErrorOutlineIcon className={classes.bannerContent} />}
          fdKey="dynamic-form-error"
        />
      );
    }

    if (loading || isEmpty(formikContext?.values?.addressFields)) {
      return (
        <SkeletonLoader
          fdKey="skeleton-form"
          rows={[
            { height: '40px', width: '100%', className: classes.dynamicFieldSkeleton },
            { height: '40px', width: '100%', className: classes.dynamicFieldSkeleton },
            { height: '40px', width: '100%', className: classes.dynamicFieldSkeleton },
          ]}
        />
      );
    }

    // should always be set by default so prevent uncontrolled components if they are set after form is returned from api
    if (!form) {
      return null;
    }

    return (
      <DynamicInputFields form={form} disabled={disabled} fieldNestingInValues="addressFields." />
    );
  };

  return renderFormBody();
};
