export enum RMSModules {
  // inventory
  STOCK_ITEMS = 'stockItems',
  STOCK_ITEM_CATEGORIES = 'stockItemCategories',
  SUPPLIERS = 'suppliers',
  TRACKING_PROFILES = 'inventoryTrackingProfiles',
  PURCHASE_ORDERS = 'purchaseOrders',
  // documents
  DOCUMENTS = 'documents',
  // accounts
  EXPENSES = 'expenses',
  BILLS = 'bills',
  CREDIT_ACCOUNT_STATUS = 'creditAccountStatus',
  ACCOUNT_CATEGORY = 'categories',
  ACCOUNT_SUB_CATEGORY = 'subCategories',
  TRANSACTION_ACCOUNTS = 'transactionAccounts',
  CREDIT_BOOKS = 'creditBooks',
  CREDIT_ACCOUNTS = 'creditAccounts',
  // payroll
  STAFF = 'staff',
  ROTA = 'rota',
  HOLIDAY_PLANNER = 'holidayPlanner',
  PAYROLL_CALCULATOR = 'payrollCalculator',
  STAFF_ROLES = 'role',
  // food safety
  TASK_LOGS = 'taskLogs',
  STOCK_ITEM_COOKING_TEMPERATURE_LOGS = 'stockItemCookingTemperatureLogs',
  STOCK_ITEM_STORAGE_TEMPERATURE_LOGS = 'stockItemStorageTemperatureLogs',
  APPLIANCE_TEMPERATURE_LOGS = 'applianceTemperatureLogs',
  INCIDENT_TYPES = 'incidentTypes',
  INCIDENT_LOGS = 'incidentLogs',
  APPLIANCE = 'appliance',
  TASKS = 'tasks',
  TASK_TYPES = 'taskTypes',

  // audit logs
  AUDIT_LOGS = 'auditLogs',

  // settings
  NETWORK_DIAGRAM = 'networkDiagram',
  PRINTING_TEMPLATES = 'printingTemplates',
  RECEIPT_TEMPLATES = 'receipt',
  KITCHEN_STATION_TEMPLATES = 'kitchenStation',
  VOUCHER_TEMPLATES = 'voucher',
  DAILY_SUMMARY_REPORT_TEMPLATES = 'dailySummaryReport',
  GUESTS = 'guests',
  PRINT_CONFIGURATION = 'printConfiguration',
  FLOAT_CONFIGURATION = 'floatConfiguration',
  TAGS = 'tags',
  PRICE_BANDS = 'priceBands',
  PAYMENT_TYPES = 'paymentTypes',
  FREEBIE_REASONS = 'freebieReasons',
  ADDRESS_MAPPING = 'addressMapping',
  LAYOUT_CONFIGURATION = 'layoutConfiguration',
  PAYROLL_CONFIGURATION = 'payrollConfiguration',
  STAFF_PERMISSIONS_CONFIGURATION = 'staffPermissionsConfiguration',
  GEO_CONFIGURATION = 'geoConfiguration',
  DELIVERY_CONFIGURATION = 'deliveryConfiguration',

  // organization
  BASIC_CONFIGURATION = 'basicConfiguration',
  OPERATING_CONFIGURATION = 'operatingConfiguration',
  DB_CONFIGURATION = 'dbConfiguration',
  API_INTEGRATIONS = 'apiIntegrations',
  CHARGES_CONFIGURATION = 'chargesConfiguration',
  BRANDS = 'brands',
  LOCALIZATION_CONFIGURATION = 'localizationConfiguration',
  ADD_NEW_PROPERTY = 'addNewProperty',
  KITCHEN_STATION_PROFILES = 'kitchenStationProfiles',
  NOMENCLATURES = 'nomenclatures',
  LICENSE = 'licenseSubscriptions',

  //sales channels
  ADD_NEW_STOREFRONT = 'addNewStoreFront',
  PAYOUT_CONFIGURATION = 'payoutConfiguration',
  OPENING_HOURS = 'openingHours',
  OPENING_HOURS_OVERRIDES = 'openingHoursOverrides',
  DELIVERY_CHARGES = 'deliveryCharges',
  TIP_CONFIGURATION = 'tipConfiguration',
  ORDER_INGEST = 'orderIngest',
  TIMINGS_CONFIGURATION = 'timingsConfiguration',
  OVER_PHONE_PAYMENT = 'overPhonePayment',
  WEB_APP_CONFIGURATION = 'webAppConfiguration',
  MOBILE_APP_CONFIGURATION = 'mobileAppConfiguration',

  // configuration/system
  STATIONS = 'stations',

  //modules/marketing
  CUSTOMERS = 'customers',
  IMPORT_CUSTOMERS = 'importCustomers',
  DISCOUNT_OFFERS = 'discountOffers',

  // Sales Module
  // modules/sales/all”
  // modules/sales/cancelledSales, etc.
  SALES_ALL = 'all',
  SALES_CANCELLED = 'cancelledSales',
  SALES_PROJECTIONS = 'salesProjections',
  SALES_STOREFRONT_COMMISSIONS = 'storefrontCommissions',
  SALES_DRIVER = 'driverSales',

  // central kitchen
  CENTRAL_KITCHEN = 'centralKitchens',
  SETUP = 'setup',
  SALES = 'sales',
  MENUS = 'menus',

  SIGNAGE = 'signagePlayers',

  //TODO: fix these when proper routing is decided
  STOREFRONT_CONFIGURATION = 'configurations/franchisors/properties/storefronts/basicConfiguration',
  STOREFRONT_OPENING_HOURS = 'configurations/franchisors/properties/storefronts/openingHours',
}
