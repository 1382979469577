import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import Add from '@mui/icons-material/Add';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useHistory } from 'react-router';

import { Button } from '@fd/ui/atoms';
import PageLayout from '@fd/ui/Layout';
import LazyComponent from '@fd/ui/LazyComponent';
import Tabs from '@fd/ui/Tabs';

import { lazyWithRetry } from '../../helpers/utilities';
import { RMSModules } from '../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../RMS/utils/rmsSubscriptions';
import CustomerList from './List/CustomersList';

const StyledImportButtonContainer = styled('div')(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  [theme.breakpoints.down('md')]: {
    paddingBottom: theme.spacing(2),
  },
  display: 'flex',
  justifyContent: 'right',
}));

const CircularProgressContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

const StyledButtonContent = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

const RMSPage = lazyWithRetry(() => import('../RMS/components/RMSPage'));

const CustomersPage = (props: MappedState) => {
  const { appId, translate } = props;
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isLoading, data } = useQueryRMSCommonDataHook(appId || '');
  const subscriptions = data?.subscriptions || [];
  const tabItems = [
    {
      label: translate('Main') as string,
      component: <CustomerList />,
      queryString: 'main',
    },
    {
      label: 'RMS',
      component: (
        <>
          <Permissions allowed={['Owner', 'ManagedOwner', 'FlipdishStaff']}>
            <StyledImportButtonContainer>
              <Button
                fullWidth={isMobile}
                fdKey={`Action-button-Import_Customers`}
                onClick={() => history.push(`/${appId}/customers/import-customers`)}
              >
                <StyledButtonContent>
                  <Add />
                  {translate('Import_Customers')}
                </StyledButtonContent>
              </Button>
            </StyledImportButtonContainer>
          </Permissions>
          <LazyComponent>
            <RMSPage
              pageLayoutEnabled={false}
              subscription={RMSSubscriptions.BASE}
              title={translate('Customers')}
              module={RMSModules.CUSTOMERS}
              url={`modules/marketing/${RMSModules.CUSTOMERS}`}
            />
          </LazyComponent>
        </>
      ),
      queryString: 'rms',
    },
  ];

  // edge case in which while loading RMS subscriptions the customers renders and overrites the url
  const oldCustomersPage = isLoading ? (
    <CircularProgressContainer>
      <CircularProgress />
    </CircularProgressContainer>
  ) : (
    <CustomerList />
  );
  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'customer.*' }}
      documentTitle={'Customers'}
      title={translate('Customers')}
    >
      {subscriptions?.includes(RMSSubscriptions.BASE) ? (
        <Tabs tabItems={tabItems} />
      ) : (
        oldCustomersPage
      )}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state?.currentApp?.AppId,
    translate: getTranslate(state.locale),
  };
};

export default connect(mapStateToProps)(CustomersPage);
