import { action, actionError } from '../../actions/utils';
import { flagService } from '../../services';
import { constants } from './constants';
import { AuditLog, cancelErrorMessage, Params, service } from './service';

const {
  GET_LOGS_REQUEST,
  GET_LOGS_FAILURE,
  GET_LOGS_SUCCESS,
  GET_LOG_REQUEST,
  GET_LOG_FAILURE,
  GET_LOG_SUCCESS,
} = constants;

// #region getAuditLogs
export type GetAuditLogsRequest = ReturnType<typeof getAuditLogsRequest>;
export const getAuditLogsRequest = () => action(GET_LOGS_REQUEST);

export type GetAuditLogsRequestSuccess = ReturnType<typeof getAuditLogsRequestSuccess>;
export const getAuditLogsRequestSuccess = (logs: {
  auditLogs: AuditLog[];
  Limit: number;
  Page: number;
  TotalRecordCount: number;
}) => action(GET_LOGS_SUCCESS, logs);

export type GetAuditLogsRequestFailure = ReturnType<typeof getAuditLogsRequestFailure>;
export const getAuditLogsRequestFailure = (error: Error) =>
  actionError(GET_LOGS_FAILURE, null, error);

export function getAuditLogs(params: Params) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    const orgId = state.currentApp.OrgId as string;

    try {
      dispatch(getAuditLogsRequest());

      const useAuditLogsForOrg = flagService.isFlagOn(state, 'auditLogsV2');

      const { Data, Limit, Page, TotalRecordCount } = await (useAuditLogsForOrg
        ? service.getAuditLogsForOrg(orgId, params)
        : service.getAuditLogs(appId, params));

      dispatch(
        getAuditLogsRequestSuccess({
          Page,
          Limit,
          auditLogs: Data,
          TotalRecordCount,
        })
      );
    } catch (error) {
      if (error.message && error.message === cancelErrorMessage) {
        return;
      }
      dispatch(getAuditLogsRequestFailure(error));
    }
  };
}
// #endregion getAuditLogs

//#region getAuditLog
export type GetAuditLogRequest = ReturnType<typeof getAuditLogRequest>;
export const getAuditLogRequest = () => action(GET_LOG_REQUEST);

export type GetAuditLogRequestSuccess = ReturnType<typeof getAuditLogRequestSuccess>;
export const getAuditLogRequestSuccess = (log: AuditLog) => action(GET_LOG_SUCCESS, log);

export type GetLogsRequestFailure = ReturnType<typeof getAuditLogRequestFailure>;
export const getAuditLogRequestFailure = (error: Error) =>
  actionError(GET_LOG_FAILURE, null, error);

export function getAuditLog(params: Params) {
  return async (dispatch: ThunkDispatch, getState: () => AppState) => {
    const state = getState();
    const appId = state.currentApp.AppId as string;
    const orgId = state.currentApp.OrgId as string;

    try {
      dispatch(getAuditLogRequest());

      const useAuditLogsForOrg = flagService.isFlagOn(state, 'auditLogsV2');

      const { Data } = await (useAuditLogsForOrg
        ? service.getAuditLogsForOrg(orgId, params)
        : service.getAuditLogs(appId, params));

      dispatch(getAuditLogRequestSuccess(Data[0]));
    } catch (error) {
      dispatch(getAuditLogRequestFailure(error));
    }
  };
}
// #endregion getAuditLog
