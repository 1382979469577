import React, { useEffect } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import { App } from '@flipdish/api-client-typescript';
import CircularProgress from '@mui/material/CircularProgress';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { storeArchive, storeUnpublish } from '../../../actions/store.actions';
import * as storeConstants from '../../../constants/store.constants';
import { createLoadingSelector } from '../../../selectors/loading.selector';
import { getSelectedStore } from '../../../selectors/store.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import PageLayout from '../../../ui/Layout';
import ArchiveStoreButton from './ArchiveStoreButton';
import DangerZoneCard from './DangerZoneCards';
import SalesChannelDangerZone from './SalesChannelDangerZone';
import UnpublishStoreButton from './UnpublishStoreButton';

export type CardData = {
  cardHeader: TranslationId;
  doThisIf: TranslationId[];
  itWillMean: TranslationId[];
  buttonText: TranslationId;
  divider: boolean;
};

const storeCardData: CardData[] = [
  {
    cardHeader: 'Unpublish_this_store',
    doThisIf: [
      'store_not_ready_to_accept_orders_yet',
      "You're_closing_for_renovations",
      'You_will_want_to_re-open',
    ],
    itWillMean: [
      'store_will_no_longer_be_visible_on_your_website_or_apps',
      "Customers_will_not_be_able_to_order_from_this_store_while_it's_unpublished",
      'The_store_will_still_be_visible_in_your_Flipdish_Portal',
    ],
    buttonText: 'Unpublish_store',
    divider: true,
  },
  {
    cardHeader: 'Archive_this_store',
    doThisIf: [
      'store_was_created_in_error_or_closed_permanently',
      "You'll_never_want_to_receive_orders_for_this_store_in_the_future",
    ],
    itWillMean: [
      'Store_will_no_longer_be_here_in_your_Flipdish_Portal',
      'store_will_no_longer_be_visible_on_your_website_or_apps',
      'Customers_no_longer_order_from_store_forever',
      'Orders_and_audit_logs_related_to_this_store_will_be_kept',
    ],
    buttonText: 'Archive_store',
    divider: false,
  },
];

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);

type Props = MappedProps & MappedDispatch;
const DangerZoneList = (props: Props) => {
  const {
    appId,
    loading,
    store,
    storeName,
    storeDetails,
    storePublished,
    unpublishStore,
    archiveStore,
  } = props;
  const loadingDependencies = loading || !store;
  useLoadStoreFromSalesChannelIdIntoRedux({ store });
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_dangerZone', {
      action: 'logged_in',
    });
  }, []);
  const { channelId } = useParams<{
    channelId: string;
  }>();

  const history = useHistory();

  const isSalesChannel = !!channelId;

  return (
    <PageLayout
      title={<Translate id="Danger_zone_title" />}
      documentTitle="Store_Danger_Zone"
      caption={storeName}
      toParent={
        store ? `/${appId.AppId}/storegroups/${store.StoreGroupId}/stores/${store.StoreId}` : ''
      }
    >
      {loadingDependencies ? (
        <CircularProgress style={{ margin: '10px' }} size={50} />
      ) : (
        <>
          {isSalesChannel ? (
            <SalesChannelDangerZone />
          ) : (
            storeCardData.map((card, index) => {
              return (
                <DangerZoneCard
                  cardHeader={card.cardHeader}
                  doThisIf={card.doThisIf}
                  itWillMean={card.itWillMean}
                  divider={card.divider}
                  button={
                    <>
                      {card.cardHeader === 'Unpublish_this_store' ? (
                        <Permissions allowed={[App.AppResourceSetEnum.EditStores]}>
                          <UnpublishStoreButton
                            card={card}
                            storeName={storeDetails.storeName}
                            isPublished={storePublished}
                            handleSubmit={() => unpublishStore(storeDetails.storeId)}
                            tooltipMessage="this_store_is_already_unpublished"
                            dialogTitle="Unpublish_Store?"
                            dialogDescription="please_type_in_the_name_of_the_store_to_confirm"
                            helperText="Store_name_does_not_match"
                          />
                        </Permissions>
                      ) : card.cardHeader === 'Archive_this_store' ? (
                        <Permissions allowed={[App.AppResourceSetEnum.EditStores]}>
                          <ArchiveStoreButton
                            buttonText="Archive_store"
                            dialogTitle="Archive_Store?"
                            dialogDescription="please_type_in_the_name_of_the_store_to_confirm"
                            helperText="Store_name_does_not_match"
                            handleSubmit={() =>
                              archiveStore(storeDetails.storeId).then(() => {
                                history.push(
                                  `/${appId.AppId}/storegroups/${store.StoreGroupId}/stores`
                                );
                              })
                            }
                            storeName={storeDetails.storeName}
                          />
                        </Permissions>
                      ) : null}
                    </>
                  }
                  key={index}
                />
              );
            })
          )}
        </>
      )}
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  unpublishStore: (storeId: number) => dispatch(storeUnpublish(storeId)),
  archiveStore: (storeId: number) => dispatch(storeArchive(storeId)),
});

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const store = getSelectedStore(state);
  const storeId = (store && store.StoreId) || 0;
  const storeName = (store && store.Name) || '';
  const storeGroupId = (store && store.StoreGroupId) || 0;
  const appId = state.currentApp;
  const storePublished = store && store.IsPublished;

  const storeDetails = { appId: appId.AppId, storeName, storeId, storeGroupId };

  return {
    appId,
    loading: initialLoadingSelector(state),
    store,
    storeDetails,
    storeGroupId,
    storeId,
    storeName,
    storePublished,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DangerZoneList);
