import React from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { Translate } from 'react-localize-redux';

import LinkButton from '../../Button/LinkButton';

const useStyles = makeStyles(({ spacing, breakpoints, palette }: Theme) => ({
  paperWrapper: {
    marginBottom: spacing(2),
    marginTop: spacing(1),
  },
  customBanner: {
    borderRadius: '8px',
    [breakpoints.down('sm')]: {
      alignContent: 'flex-start',
      marginLeft: spacing(2),
      marginRight: spacing(2),
      width: '93%',
    },
  },
  yellowBackground: {
    backgroundColor: '#ffbc2c',
  },
  redBackground: {
    backgroundColor: palette.secondary.main,
  },
  greenBackground: {
    backgroundColor: palette.success.light,
  },
  blueBackground: {
    backgroundColor: '#ADE3F4',
  },
  textContentYellow: {
    color: palette.common.black,
    wordBreak: 'break-word',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
  textContentRed: {
    color: palette.common.white,
    wordBreak: 'break-word',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
  textContentGreen: {
    color: palette.common.white,
    wordBreak: 'break-word',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
  textContentBlue: {
    color: palette.common.black,
    wordBreak: 'break-word',
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
  },
  minWidth: {
    minWidth: 'fit-content',
  },
}));

type Props = {
  backgroundColor: 'yellow' | 'red' | 'green' | 'blue' | 'default';
  bannerText?: TranslationId;
  buttonText?: TranslationId;
  translatedBannerText?: string;
  translatedButtonText?: string;
  showButton?: boolean;
  toUrl?: string;
  userPermissions?: string;
};

export const DynamicAlertBanner = (props: Props) => {
  const classes = useStyles();
  const {
    backgroundColor,
    bannerText,
    buttonText,
    showButton = false,
    toUrl,
    translatedBannerText,
    translatedButtonText,
  } = props;

  const showBtn = location.pathname !== toUrl && showButton;

  return (
    <Grid
      container
      alignItems="flex-start"
      className={clsx(
        classes.customBanner,
        backgroundColor === 'yellow' && classes.yellowBackground,
        backgroundColor === 'red' && classes.redBackground,
        backgroundColor === 'green' && classes.greenBackground,
        backgroundColor === 'blue' && classes.blueBackground,
        classes.paperWrapper
      )}
    >
      <Grid item xs={12}>
        <Toolbar>
          <Typography
            variant="body2"
            className={clsx(
              backgroundColor === 'yellow' && classes.textContentYellow,
              backgroundColor === 'red' && classes.textContentRed,
              backgroundColor === 'green' && classes.textContentGreen,
              backgroundColor === 'blue' && classes.textContentBlue
            )}
          >
            {bannerText && <Translate id={bannerText} />}
            {translatedBannerText}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          {showBtn && (
            <Hidden smDown>
              <Box className={classes.minWidth}>
                <LinkButton
                  color="primary"
                  fdKey={`${buttonText}_btn`}
                  to={toUrl ?? ''}
                  type="button"
                  variant="text"
                >
                  {buttonText && <Translate id={buttonText} />}
                  {translatedButtonText}
                </LinkButton>
              </Box>
            </Hidden>
          )}
        </Toolbar>
      </Grid>
      {showBtn && (
        <Hidden smUp>
          <Grid item xs={12}>
            <Box ml={1} mr={2} mb={1}>
              <LinkButton
                color="primary"
                fdKey={`${buttonText}_btn`}
                to={toUrl ?? ''}
                type="button"
                variant="text"
              >
                {buttonText && <Translate id={buttonText} />}
                {translatedButtonText}
              </LinkButton>
            </Box>
          </Grid>
        </Hidden>
      )}
    </Grid>
  );
};

export default DynamicAlertBanner;
