import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import { SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { useHistory } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';
import LazyComponent from '@fd/ui/LazyComponent';
import Tabs from '@fd/ui/Tabs';

import { notifyError, NotifyProps } from '../../../../../layouts/Notify/actions';
import { flagService } from '../../../../../services/flagService/flagService';
import SalesChannelList from '../../../../RMS/SalesChannels/SalesChannelList';
import KioskDevicesTab from '../../../components/FlipdishDevicesTab';
import { Discovery } from '../../components/Discovery';
import { LegacyMobileSalesChannelsConfig } from '../../components/LegacyMobileSalesChannelsConfig';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
  },
}));

const MobileApps = (props: MappedState & MappedDispatch) => {
  const {
    hasAndroidApp,
    hasIosApp,
    translate,
    currentApp,
    selectedProperty,
    showSalesChannelsFlag,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const handleChannelClick = (salesChannel) => {
    history.push(
      `/${currentApp?.AppId}/sales-channels/mobile-apps/property/${selectedProperty?.code}/sales-channel/${salesChannel.salesChannelId}`
    );
  };

  const { data } = useQueryRMSCommonDataHook(currentApp?.AppId || '');
  const showSalesChannels = data.associations?.isMigratedToSalesChannels || showSalesChannelsFlag;

  const tabItems = [
    ...(showSalesChannels
      ? [
          {
            label: translate('Sales_Channels') as string,
            component: (
              <LazyComponent>
                <SalesChannelList
                  filterByType={[SalesChannelType.FLIPDISH_MOBILE_APP]}
                  onChannelClick={handleChannelClick}
                  hideFilter={false}
                />
              </LazyComponent>
            ),
            queryString: 'sales-channels',
          },
        ]
      : []),
    {
      label: translate('Devices') as string,
      component: (
        <LazyComponent>
          <KioskDevicesTab />
        </LazyComponent>
      ),
      queryString: 'devices',
    },
    {
      label: translate('Settings') as string,
      component: <LegacyMobileSalesChannelsConfig />,
      queryString: 'settings',
    },
  ];
  const renderLegacyPage = () => {
    if (hasAndroidApp || hasIosApp) {
      return <Tabs tabItems={tabItems} />;
    } else {
      return <Discovery translate={translate} />;
    }
  };

  return (
    <PageLayout documentTitle="Mobile_apps" title={<Translate id="Mobile_apps" />}>
      <Permissions allowed={['ViewSalesChannels', 'EditSalesChannels']}>
        <div className={classes.container}>{renderLegacyPage()}</div>
      </Permissions>
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  notifyError: (data: NotifyProps) => dispatch(notifyError(data)),
});

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp, rms } = state;
  return {
    hasIosApp: currentApp.HasIosApp,
    hasAndroidApp: currentApp.HasAndroidApp,
    translate: getTranslate(state.locale),
    selectedProperty: rms.selectedProperty,
    showSalesChannelsFlag: flagService.isFlagOn(state, 'showSalesChannelStructure'),
    currentApp,
  };
};

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(MobileApps);
export { EnhancedComponent as MobileApps };
