export enum RMSSubscriptions {
  FOOD_SAFETY = 'pos.food-safety',
  ACCOUNTS = 'pos.accounts',
  INVENTORY = 'pos.inventory',
  DOCUMENTS = 'pos.documents',
  PAYROLL = 'pos.payroll',
  BASE = 'pos.base',
  CENTRAL_KITCHEN = 'pos.central-kitchen',
  SIGNAGE = 'pos.signage',
}
