import React from 'react';

import AnalyticsIcon from '@mui/icons-material/AnalyticsOutlined';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLateOutlined';
import BookmarksIcon from '@mui/icons-material/BookmarksOutlined';
import ChecklistIcon from '@mui/icons-material/ChecklistOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweepOutlined';
import DriveEtaIcon from '@mui/icons-material/DriveEtaOutlined';
import EventNoteIcon from '@mui/icons-material/EventNoteOutlined';
import FoodBankIcon from '@mui/icons-material/FoodBankOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import LockClockIcon from '@mui/icons-material/LockClockOutlined';
import MenuBookIcon from '@mui/icons-material/MenuBookOutlined';
import MoneyOffIcon from '@mui/icons-material/MoneyOffOutlined';
import PaidIcon from '@mui/icons-material/PaidOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActionsOutlined';
import PercentIcon from '@mui/icons-material/PercentOutlined';
import PlaceIcon from '@mui/icons-material/PlaceOutlined';
import PriceChangeIcon from '@mui/icons-material/PriceChangeOutlined';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrorsOutlined';
import SsidChartIcon from '@mui/icons-material/SsidChartOutlined';
import TodayIcon from '@mui/icons-material/TodayOutlined';
import UpdateIcon from '@mui/icons-material/UpdateOutlined';

import { RMSSubscriptions } from '../utils/rmsSubscriptions';

export type RmsReport = {
  title: TranslationId;
  subtitle: TranslationId;
  path: string;
  icon: JSX.Element;
  allowFranchisorLevel?: boolean;
};

export type RmsReportGroup = {
  path: string;
  title: TranslationId;
  caption: TranslationId;
  documentTitle: string;
  subscription: RMSSubscriptions;
  reports: RmsReport[];
};

export const Data: RmsReportGroup[] = [
  {
    path: '/sales',
    title: 'Sales',
    caption: 'Sales_BI_Tools',
    documentTitle: 'Sales',
    subscription: RMSSubscriptions.BASE,
    reports: [
      {
        title: 'Sales_Overview',
        subtitle: 'Periodic_Sales_Overview_Analytics',
        icon: <AnalyticsIcon />,
        path: '/sales/overview',
        allowFranchisorLevel: true,
      },
      {
        title: 'Sales_VAT',
        subtitle: 'Sales_VAT',
        icon: <PercentIcon />,
        path: '/sales/vat',
        allowFranchisorLevel: true,
      },
      {
        title: 'End_Of_Day_Report',
        subtitle: 'Daily_Float_Sessions_Summary_Report',
        icon: <TodayIcon />,
        path: '/sales/endOfDay',
      },
      {
        title: 'Deposit_Transactions_Report',
        subtitle: 'Deposit_Transactions_Report',
        icon: <PendingActionsIcon />,
        path: '/sales/drs',
      },
      {
        title: 'Kitchen_Timings_Report',
        subtitle: 'Kitchen_Timings_Report_Subtitle',
        icon: <RunningWithErrorsIcon />,
        path: '/sales/timings',
      },
      {
        title: 'Sales_Discounts',
        subtitle: 'Sales_Discounts',
        icon: <PriceChangeIcon />,
        path: '/sales/discounts',
        allowFranchisorLevel: true,
      },
      {
        title: 'Reports_RMS_Title_Sales_Refunds',
        subtitle: 'Reports_RMS_Subtitle_Sales_Refunds',
        icon: <CurrencyExchangeIcon />,
        path: '/sales/refunds',
        allowFranchisorLevel: true,
      },
      {
        title: 'Reports_RMS_Title_Sales_Charges',
        subtitle: 'Reports_RMS_Subtitle_Sales_Charges',
        icon: <ChecklistIcon />,
        path: '/sales/charges',
        allowFranchisorLevel: true,
      },
      {
        title: 'Reports_RMS_Title_Sales_Hourly',
        subtitle: 'Reports_RMS_Subtitle_Sales_Hourly',
        icon: <UpdateIcon />,
        path: '/sales/hourly',
        allowFranchisorLevel: true,
      },
    ],
  },

  {
    path: '/costs',
    title: 'Costs',
    caption: 'Costs_BI_Tools',
    documentTitle: 'Costs',
    subscription: RMSSubscriptions.BASE,
    reports: [
      {
        title: 'Costs_Overview_Report',
        subtitle: 'Periodic_Costs_Summary_Report',
        icon: <SsidChartIcon />,
        path: '/costs/overview',
        allowFranchisorLevel: true,
      },
      {
        title: 'Wages_Costs',
        subtitle: 'Payroll_Wages_Costs',
        icon: <PaidIcon />,
        path: '/costs/wages',
        allowFranchisorLevel: true,
      },
      {
        title: 'Venue_Commissions',
        subtitle: 'Venue_Commissions',
        icon: <PlaceIcon />,
        path: '/costs/venueCommissions',
        allowFranchisorLevel: true,
      },
      {
        title: 'Daily_COGS_Summary',
        subtitle: 'Daily_COGS_Summary',
        icon: <PriceChangeIcon />,
        path: '/costs/cogsSummary',
      },
      {
        title: 'Fixed_Costs',
        subtitle: 'Fixed_Costs',
        icon: <LockClockIcon />,
        path: '/costs/fixedCosts',
        allowFranchisorLevel: true,
      },
      {
        title: 'Staff_Meals',
        subtitle: 'Daily_Staff_Meals',
        icon: <FoodBankIcon />,
        path: '/costs/staffMeals',
      },
      {
        title: 'Guest_Meals',
        subtitle: 'Daily_Guest_Meals',
        icon: <GroupIcon />,
        path: '/costs/guestMeals',
      },
      {
        title: 'Freebies_Meals',
        subtitle: 'Daily_Freebies_Meals',
        icon: <MoneyOffIcon />,
        path: '/costs/freebies',
      },
    ],
  },

  {
    path: '/payroll',
    title: 'Payroll',
    caption: 'Payroll_BI_Tools',
    documentTitle: 'Payroll',
    subscription: RMSSubscriptions.PAYROLL,
    reports: [
      {
        title: 'Daily_Payroll_Costs',
        subtitle: 'Daily_Payroll_Costs',
        icon: <PaidIcon />,
        path: '/costs/wages',
        allowFranchisorLevel: true,
      },
      {
        title: 'Daily_Sales_By_Driver',
        subtitle: 'Daily_Sales_By_Driver',
        icon: <DriveEtaIcon />,
        path: '/payroll/driverSales',
        allowFranchisorLevel: true,
      },
      {
        title: 'Upselling_Points',
        subtitle: 'Upselling_Points',
        icon: <ArrowCircleUpIcon />,
        path: '/payroll/upsellingPoints',
        allowFranchisorLevel: true,
      },
    ],
  },
  {
    path: '/inventory',
    title: 'Inventory',
    caption: 'Inventory_BI_Tools',
    documentTitle: 'Inventory',
    subscription: RMSSubscriptions.INVENTORY,
    reports: [
      {
        title: 'Purchase_Orders',
        subtitle: 'Purchase_Orders',
        icon: <PendingActionsIcon />,
        path: '/inventory/purchaseOrders',
      },
      {
        title: 'Wastages',
        subtitle: 'Inventory_Wastages',
        icon: <DeleteSweepIcon />,
        path: '/inventory/wastages',
      },
      {
        title: 'Discrepancies',
        subtitle: 'Discrepancies_Report_Subtitle',
        icon: <AssignmentLateIcon />,
        path: '/inventory/discrepancies',
      },
      {
        title: 'Inventory_Status',
        subtitle: 'Inventory_Status_By_Date_Range',
        icon: <PendingActionsIcon />,
        path: '/inventory/inventoryStatus',
      },
      {
        title: 'Daily_COGS_Summary',
        subtitle: 'Daily_COGS_Summary',
        icon: <PriceChangeIcon />,
        path: '/costs/cogsSummary',
      },
      {
        title: 'Sales_By_Product',
        subtitle: 'Retail_And_Cost_Price_By_Product_Sales',
        icon: <EventNoteIcon />,
        path: '/inventory/salesByProduct',
      },
    ],
  },
  {
    path: '/menu',
    title: 'Menu',
    caption: 'Menu_BI_Tools',
    documentTitle: 'Menu',
    subscription: RMSSubscriptions.BASE,
    reports: [
      {
        title: 'Menu_Item_Sales',
        subtitle: 'Sales_By_Menu_Items',
        icon: <MenuBookIcon />,
        path: '/menu/salesByMenuItem',
        allowFranchisorLevel: true,
      },
      {
        title: 'Hourly_Menu_Item_Sales',
        subtitle: 'Hourly_Sales_By_Menu_Items',
        icon: <MenuBookIcon />,
        path: '/menu/hourlySalesByMenuItem',
        allowFranchisorLevel: true,
      },
      {
        title: 'Reporting_Tag_Sales',
        subtitle: 'Sales_By_Menu_Item_Reporting_Tags',
        icon: <BookmarksIcon />,
        path: '/menu/salesByReportingTag',
        allowFranchisorLevel: true,
      },
      {
        title: 'Hourly_Reporting_Tag_Sales',
        subtitle: 'Hourly_Sales_By_Menu_Item_Reporting_Tags',
        icon: <BookmarksIcon />,
        path: '/menu/hourlySalesByReportingTag',
        allowFranchisorLevel: true,
      },
      {
        title: 'Menu_Item_Refunds',
        subtitle: 'Refunds_By_Menu_Item',
        icon: <CurrencyExchangeIcon />,
        path: '/menu/refundsByMenuItem',
        allowFranchisorLevel: true,
      },
    ],
  },
];
