import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import MenuIcon from '@mui/icons-material/Menu';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { type Theme, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';

import { ReactComponent as FlipdishLogo } from '../../assets/images/FlipdishLogo.svg';
import { ProfileMenu } from '../../components/ProfileMenu';
import BrandSelector from '../../components/RMS/components/BrandSelector';
import { SupportMenu } from '../../components/SupportMenu';
import HelpMenu from './HelpMenu';
import TopNavMobileExpandedMenu from './TopNavMobileExpandedMenu';

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    zIndex: 'auto',
    backgroundColor: '#fff',
    borderBottom: 'solid 1px #dbdcdf',
  },
  menuButton: {
    marginRight: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  switch: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    marginRight: 16,
  },
  holder: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  subBar: {
    display: 'flex',
    marginLeft: 'auto',
    alignItems: 'center',
  },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    textTransform: 'none',
  },
  icon: {
    width: '16px',
    height: '18px',
  },
  button: {
    marginLeft: theme.spacing(1.5),
  },
}));

type Props = {
  appId?: string;
  open: boolean;
  toggleSideNav: () => void;
  tutorialIsOpen?: boolean;
  setTutorialIsOpen: (boolean) => void;
  tutorialHasNotification?: boolean;
  showOpenRMSButton?: boolean;
};
const TopNav = ({
  appId,
  toggleSideNav,
  tutorialIsOpen,
  setTutorialIsOpen,
  // tutorialHasNotification, HIDING Tutorial until work is done to update its api calls etc CLX-1508
  showOpenRMSButton,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  const rmsData = useQueryRMSCommonDataHook(appId ?? '');
  let franchisorId = '';

  if (rmsData.data?.associations) {
    franchisorId = rmsData.data.associations?.orgHierarchy.franchisor.id;
  }

  const rmsUrl = window.location.host.includes('portal.flipdish.com')
    ? `https://rms.portal.flipdish.com?franchisorId=${franchisorId}`
    : `https://rms.portal.flipdishdev.com?franchisorId=${franchisorId}`;
  return (
    <AppBar
      enableColorOnDark
      color="default"
      elevation={0}
      position="static"
      className={classes.appBar}
    >
      <Toolbar>
        <IconButton
          onClick={toggleSideNav}
          color="inherit"
          aria-label="Open drawer"
          data-fd="drawer_toggle"
          edge="start"
          className={classes.menuButton}
        >
          <MenuIcon />
        </IconButton>

        <FlipdishLogo className={classes.logo} />

        <div className={classes.subBar}>
          {isDesktop ? (
            <>
              <SupportMenu />
              {/* HIDING Tutorial until work is done to update its api calls etc CLX-1508 */}
              {/* <TutorialButton
                tutorialIsOpen={tutorialIsOpen}
                setTutorialIsOpen={setTutorialIsOpen}
                hasNotification={tutorialHasNotification}
              /> */}
              <HelpMenu />
            </>
          ) : (
            <TopNavMobileExpandedMenu
              tutorialIsOpen={tutorialIsOpen}
              setTutorialIsOpen={setTutorialIsOpen}
            />
          )}
          {isDesktop && <ProfileMenu />}
          <div className={classes.holder}>
            <BrandSelector />
          </div>
        </div>
        {isDesktop && showOpenRMSButton && !!rmsData.data?.subscriptions?.length && (
          <Permissions allowed={['Owner', 'FlipdishStaff', 'ManagedOwner']}>
            <Button
              variant="outlined"
              color="inherit"
              size="medium"
              className={classes.button}
              onClick={() => window.open(rmsUrl, '_blank', 'noopener,noreferrer')}
            >
              <div className={classes.buttonContent}>
                <OpenInNewOutlinedIcon className={classes.icon} />
                {<Translate id="Open_RMS_Portal" />}
              </div>
            </Button>
          </Permissions>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopNav;
