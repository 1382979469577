import { useQuery } from '@tanstack/react-query';

import { getAssociations, getAssociationsKey } from '../components/RMS/rms.services';
import { RMSSubscriptions } from '../components/RMS/utils/rmsSubscriptions';
import { FIVE_MINUTES } from '../helpers/timeConstants';

// TODO: Manage invalidating queries when new page is loaded/new user logs in
// TODO: Create Proper types once RMS data is known
export function useQueryRMSCommonDataHook(appId: string) {
  const {
    isFetching: isLoadingAssociations,
    data: associationsResponse,
    error: associationsError,
  } = useQuery({
    queryKey: [getAssociationsKey, appId],
    queryFn: () => getAssociations(appId),
    retry: 1,
    // 5 minutes stale time - default is 0 causing refetch on new iframe load
    staleTime: FIVE_MINUTES,
  });

  const subscriptions: Array<RMSSubscriptions> = [];
  associationsResponse?.data?.orgHierarchy?.properties?.forEach((property) => {
    property?.subscriptions?.length > 0 &&
      subscriptions.push(...(property.subscriptions as Array<RMSSubscriptions>));
  });

  return {
    isLoading: isLoadingAssociations,
    data: {
      associations: associationsResponse?.data,
      subscriptions,
    },
    error: associationsError,
  };
}

export default useQueryRMSCommonDataHook;
