import { getSubscriptionsApiEndPoint } from '../helpers/apibase';
import { createApi } from '../helpers/utilities';
import {
  EntitlementOverrideRequest,
  EntitlementsApiFactory,
} from '../overrides/@flipdish/subscriptions-api-client-typescript/api';
import { mapServerError } from './utils/serverErrorMapper';

const entitlementsApi = createApi(EntitlementsApiFactory, getSubscriptionsApiEndPoint());

export const getEntitlementsKey = 'getEntitlements';
export const getEntitlements = async (
  orgId: string,
  expand: Array<'source'> | undefined = undefined
) => {
  try {
    return await entitlementsApi.getOrganisationEntitlements(orgId, expand);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};

export const upsertEntitlementOverridesKey = 'upsertEntitlementOverrides';
export const upsertEntitlementOverrides = async (
  appId: string,
  body: EntitlementOverrideRequest
) => {
  try {
    return await entitlementsApi.upsertAppEntitlementOverrides(appId, body);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
};
