import React, { useCallback } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import { type Store, type StoreBase, StoreGroup } from '@flipdish/api-client-typescript';
import InputAdornment from '@mui/material/InputAdornment';
import Paper from '@mui/material/Paper';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { EnableSwitch, InputField, Typography } from '@fd/ui/atoms';
import { FormSection } from '@fd/ui/molecules';
import { FailedResponseMessage } from '@fd/ui/molecules/FailedResponseMessage';

import { storeActions } from '../../actions/store.actions';
import * as storeConstants from '../../constants/store.constants';
import { getSymbol as getCurrencySymbol } from '../../helpers/currency';
import { priceRegExp } from '../../helpers/validation';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../selectors/loading.selector';
import { permissionsSelector } from '../../selectors/permissions.selector';
import { getSelectedStore } from '../../selectors/store.selector';
import { createStoreGroupSelectorById } from '../../selectors/storegroup.selector';
import { flagService } from '../../services/flagService';
import PageLayout from '../../ui/Layout';
import { HelpdeskButton } from '../HelpdeskButton';
import { LeadTimeFormField } from './components/LeadTimeFormField';
import { useLeadTime } from './hooks/useLeadTime';

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);
const initialLoadingErrorSelector = createLoadingErrorSelectorFactory([storeConstants.STORE_LOAD]);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  descriptionContainer: {
    marginBottom: theme.spacing(3),
  },
  inputField: {
    maxWidth: '146px',
  },
}));

export type update = (storeId: number, store: StoreBase) => (dispatch: any) => void;

type Props = MapStateToProps & MappedDispatch;
const StoreCollectionSettings = (props: Props) => {
  const {
    canEdit,
    currency,
    getStoreLeadTimes,
    isLeadTimeEnabled,
    leadTimes,
    loading,
    loadingError,
    onChecked,
    onMinValueChange,
    updateStoreLeadTimes,
    store,
    translate,
    update,
  } = props;
  const classes = useStyles();
  useLoadStoreFromSalesChannelIdIntoRedux({ store });
  const currencySymbol = getCurrencySymbol(currency);
  const isLoadingDependencies = loading || !store || (isLeadTimeEnabled && !leadTimes);
  const isCollectionEnabled = store?.PickupEnabled;
  const isFormFieldDisabled = !canEdit || !isCollectionEnabled;

  const { data: leadTime } = useLeadTime(
    getStoreLeadTimes,
    'collection',
    store?.StoreId,
    leadTimes,
    isLeadTimeEnabled
  );
  const backToParent = useCallback(({ history }: any) => {
    const parent = history.location.pathname.replace('/collection', '');
    history.push(parent);
  }, []);
  return (
    <PageLayout
      toParent={backToParent}
      caption={(store && store.Name) || ''}
      documentTitle="Collection_settings_title"
      title={<Translate id="Collection_settings_title" />}
    >
      {loadingError ? (
        <FailedResponseMessage
          description="Collection_settings_error_message"
          title="Error_fetching_collection_settings"
          showRetryButton={false}
        />
      ) : (
        <Paper className={classes.container}>
          <FormSection
            isLoading={isLoadingDependencies}
            sectionTitle={translate('Accept_pickup_orders') as string}
          >
            <EnableSwitch
              checked={store?.PickupEnabled}
              disabled={!canEdit}
              fdKey="pickup_enabled"
              inputProps={{
                'aria-label': translate('Accept_pickup_orders') as string,
              }}
              name="PickupEnabled"
              onChange={(e) => store && onChecked(e, store, update)}
            />
          </FormSection>

          <FormSection
            isLoading={isLoadingDependencies}
            sectionTitle={translate('Minimum_order_amount') as string}
          >
            <div className={classes.descriptionContainer}>
              <Typography variant="caption">
                <Translate id="Customers-will-not-be-able-to-place-collection-orders-when-below" />
              </Typography>
            </div>
            <InputField
              className={classes.inputField}
              fdKey="minimum_collection_amount-container"
              name="MinimumPickupOrderAmount"
              defaultValue={store?.MinimumPickupOrderAmount?.toFixed(2) || 0}
              onBlur={(e) => store && onMinValueChange(e, store, update)}
              disabled={isFormFieldDisabled}
              label={translate('Minimum_amount') as string}
              type="number"
              inputProps={{
                inputMode: 'decimal',
                pattern: priceRegExp,
                step: '0.01',
                min: '0',
                'data-fd': 'minimum_collection_amount',
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
              }}
              variant="outlined"
            />
          </FormSection>
          <FormSection
            isLoading={isLoadingDependencies}
            sectionTitle={translate('Require_Customer_Name') as string}
          >
            <EnableSwitch
              checked={store?.RequireCustomerNameForPickup}
              disabled={isFormFieldDisabled}
              fdKey="require_customer_name_for_pickup_orders"
              inputProps={{
                'aria-label': translate('Require_Customer_Name') as string,
              }}
              name="RequireCustomerNameForPickup"
              onChange={(e) => store && onChecked(e, store, update)}
            />
          </FormSection>
          <FormSection
            isLoading={isLoadingDependencies}
            sectionTitle={translate('Accept_cash_as_payment_method') as string}
          >
            <EnableSwitch
              checked={store?.CashOrdersPickupEnabled}
              disabled={isFormFieldDisabled}
              fdKey="accept_cash_for_pickup_orders"
              inputProps={{
                'aria-label': translate('Accept_cash_as_payment_method') as string,
              }}
              name="CashOrdersPickupEnabled"
              onChange={(e) => store && onChecked(e, store, update)}
            />
          </FormSection>
          {isLeadTimeEnabled && (
            <LeadTimeFormField
              description={translate('Auto_accept_lead_time_description_collection') as string}
              isDisabled={isFormFieldDisabled}
              isLoading={isLoadingDependencies}
              onBlur={(newValue) => store?.StoreId && updateStoreLeadTimes(store.StoreId, newValue)}
              translate={translate}
              value={leadTime}
            />
          )}
          <FormSection
            isLoading={isLoadingDependencies}
            sectionTitle={translate('SMS_messaging') as string}
            showDivider={false}
          >
            <div className={classes.descriptionContainer}>
              <Typography variant="caption">
                <Translate id="Include_estimated_collection_time_in_sms" />
              </Typography>{' '}
              <HelpdeskButton
                label="portalhelp:collectionsettings-includeestimatedcollectiontimeinsms"
                text="Learn_more"
              />
            </div>
            <EnableSwitch
              checked={store?.EtaInPickupConfirmationSmsEnabled}
              disabled={isFormFieldDisabled}
              fdKey="include_estimated_collection_time_in_sms"
              inputProps={{
                'aria-label': translate('Include_estimated_collection_time_in_sms') as string,
              }}
              name="EtaInPickupConfirmationSmsEnabled"
              onChange={(e) => store && onChecked(e, store, update)}
            />
          </FormSection>
          {/* HIDING Tutorial until work is done to update its api calls etc CLX-1508 */}
          {/* {store && (
            <TutorialNotifier
              storeId={store?.StoreId}
              onboardingItemId={202}
              status={'Completed' as OnboardingItemUpdate}
            />
          )} */}
        </Paper>
      )}
    </PageLayout>
  );
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    getStoreLeadTimes: (storeId: number) => {
      dispatch(storeActions.getStoreLeadTimes(storeId));
    },
    updateStoreLeadTimes: (storeId: number, leadTime: number) => {
      dispatch(
        storeActions.updateStoreLeadTimes(storeId, {
          DispatchType: 'collection',
          LeadTimeMinutes: leadTime,
        })
      );
    },
    onChecked: (event: React.ChangeEvent<HTMLInputElement>, store: Store, update: update) => {
      store &&
        store.StoreId &&
        dispatch(
          update(store.StoreId, {
            ...store,
            [event.target.name]: event.target.checked,
          })
        );
    },
    onMinValueChange: (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      store: Store,
      update: update
    ) => {
      if (store) {
        const value = Number(event.target.value);
        const formattedValue = value.toFixed(2);
        if (parseFloat(formattedValue) >= 0) {
          dispatch(
            update(store.StoreId as number, {
              ...store,
              [event.target.name]: parseFloat(formattedValue),
            })
          );
        }
      }
    },
  };
};

type MapStateToProps = ReturnType<ReturnType<typeof mapStatePropsFactory>>;
const mapStatePropsFactory = () => {
  const getPermissionsSelector = permissionsSelector.hasPermissionFactory([
    'UpdateStoresOpenForCollectionOrDelivery',
  ]);
  const updateFunction = storeActions.update(true);
  return (state: AppState) => {
    const store = getSelectedStore(state);
    const storeGroupId = store?.StoreGroupId ?? '';
    const storeGroupSelectorById = createStoreGroupSelectorById(storeGroupId);
    let Currency = StoreGroup.CurrencyEnum.EUR;
    const storeGroup = storeGroupSelectorById(state);
    if (storeGroup != null) {
      Currency = storeGroup.Currency ? storeGroup.Currency : Currency;
    }

    return {
      isLeadTimeEnabled: flagService.isFlagOn(state, 'deliveryLeadTime'),
      selectedApp: state.currentApp,
      store: store,
      leadTimes: state.stores.leadTimes,
      loading: initialLoadingSelector(state),
      loadingError: initialLoadingErrorSelector(state),
      update: updateFunction,
      currency: Currency,
      canEdit: getPermissionsSelector(state),
      translate: getTranslate(state.locale),
    };
  };
};

const EnhancedComponent = connect(
  mapStatePropsFactory,
  mapDispatchToProps
)(StoreCollectionSettings);

export { EnhancedComponent as StoreCollectionSettings };
