import React from 'react';

import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import List from '@mui/material/List';
import { Translate } from 'react-localize-redux';

import PageLayout from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import ListItemLinkButton from '@fd/ui/List/ListItemLinkButton';

import { toKebabCase } from '../../helpers/strings';
import { RMSModules } from '../RMS/utils/rmsModules';

const StaffSettingsList = ({ appId, propertyId }: { appId?: string; propertyId?: string }) => {
  const basePropertyUrl = `/${appId}/properties/${propertyId}/staff`;
  return (
    <PageLayout
      title={<Translate id="Staff" />}
      toParent={`/${appId}/properties/${propertyId}?tab=settings`}
      strictToParent
    >
      <PaperContainer fluid>
        <List>
          <ListItemLinkButton
            subtitle="Manage_staff"
            title="Staff"
            iconComponent={<GroupsOutlinedIcon />}
            link={`${basePropertyUrl}/manage`}
          />
          <ListItemLinkButton
            title="Staff_Roles"
            subtitle="Define_staff_roles"
            link={`${basePropertyUrl}/${toKebabCase(RMSModules.STAFF_ROLES)}`}
            iconComponent={<ManageAccountsOutlinedIcon />}
          />
          <ListItemLinkButton
            title={'Staff_Permissions'}
            subtitle={'Set_pin_access_clock_in_settings'}
            iconComponent={<VpnKeyOutlinedIcon />}
            link={`${basePropertyUrl}/${toKebabCase(RMSModules.STAFF_PERMISSIONS_CONFIGURATION)}`}
            isLast={true}
          />
        </List>
      </PaperContainer>
    </PageLayout>
  );
};

export default StaffSettingsList;
