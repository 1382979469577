import React from 'react';

import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { toKebabCase } from '../../../helpers/strings';
import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';

const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));
const Payroll = lazyWithRetry(() => import('./Payroll'));

const PayrollRoutes: React.FC<React.PropsWithChildren<RouteComponentProps>> = (
  props: RouteComponentProps
) => {
  const {
    match: { path },
  } = props;
  const baseUrl = `modules/payroll`;
  return (
    <ErrorBoundary identifier="payroll">
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <LazyComponent>
              <Payroll />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.ROTA)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.PAYROLL}
                title={<Translate id="Rota" />}
                module={RMSModules.ROTA}
                url={`${baseUrl}/${RMSModules.ROTA}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.PAYROLL_CALCULATOR)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.PAYROLL}
                title={<Translate id="Payroll_Calculator" />}
                module={RMSModules.PAYROLL_CALCULATOR}
                url={`${baseUrl}/${RMSModules.PAYROLL_CALCULATOR}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.HOLIDAY_PLANNER)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.PAYROLL}
                title={<Translate id="Holiday_Planner" />}
                module={RMSModules.HOLIDAY_PLANNER}
                url={`${baseUrl}/${RMSModules.HOLIDAY_PLANNER}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.PAYROLL_CONFIGURATION)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                subscription={RMSSubscriptions.BASE}
                title={<Translate id="Payroll_Configuration" />}
                module={RMSModules.PAYROLL_CONFIGURATION}
                url={`configurations/franchisors/properties/${RMSModules.PAYROLL_CONFIGURATION}`}
                includePropertyIdInUrl
              />
            </LazyComponent>
          )}
        />
      </Switch>
    </ErrorBoundary>
  );
};

export default PayrollRoutes;
