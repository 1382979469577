import React from 'react';

import { App } from '@flipdish/api-client-typescript';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { checkVisibility } from 'react-redux-permissions/dist/core';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { compose } from 'recompose';

import LazyComponent from '@fd/ui/LazyComponent';

import { toKebabCase } from '../../helpers/strings';
import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import RMSPage from '../RMS/components/RMSPage';
import { RMSModules } from '../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../RMS/utils/rmsSubscriptions';
import CustomersPage from './CustomersPage';
import CustomerDetails from './Details/CustomerDetails';

type Props = MappedProps & RouteComponentProps;

const CustomerRoutes: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    appId,
    translate,
    match: { path },
    canEdit,
  } = props;
  return (
    <ErrorBoundary identifier="customers">
      <Switch>
        <Route
          exact
          path={`${path}/${toKebabCase(RMSModules.IMPORT_CUSTOMERS)}`}
          render={() => (
            <LazyComponent>
              <RMSPage
                toParent={`/${appId}/customers?tab=rms`}
                subscription={RMSSubscriptions.BASE}
                title={translate('Import_Customers')}
                module={RMSModules.IMPORT_CUSTOMERS}
                url={`modules/marketing/${RMSModules.IMPORT_CUSTOMERS}`}
              />
            </LazyComponent>
          )}
        />
        {canEdit && <Route exact path={`${path}/:customerId`} component={CustomerDetails} />}
        <Route path={`${path}`} component={CustomersPage} />
      </Switch>
    </ErrorBoundary>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const canEdit = checkVisibility(
    state.permissions,
    [App.AppResourceSetEnum.ViewCustomerStatistics],
    []
  );
  return {
    appId: state.currentApp?.AppId,
    canEdit,
    translate: getTranslate(state.locale),
  };
};

export default compose<Props, RouteComponentProps>(connect(mapStateToProps))(CustomerRoutes);
