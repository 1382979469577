import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { Org } from '@flipdish/orgmanagement';
import Drawer from '@mui/material/Drawer';
import { type Theme, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppLoadingCircularProgress } from '../../AppLoadingCircularProgress';
import organisationService from '../organisation.services';
import OrgCreateAdminBtn from './OrgCreateAdminBtn';
import OrgSelectorItem from './OrgSelectorItem';
import OrgSelectorItems from './OrgSelectorItems';
import OrgSelectorSearch from './OrgSelectorSearch';

const drawerWidth = 304;
const limit = 20;
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    zIndex: 1350,
  },
  drawer: {
    width: drawerWidth,
    wordBreak: 'break-all',
  },
  drawerOpen: {
    borderRight: 'none',
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      bottom: 0,
      top: 65,
    },
    right: 0,
  },
  drawerClose: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 65,
    },

    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    right: -drawerWidth,
  },
  loadingContainer: {
    height: '100%',
  },
}));

const ListContainer = styled('div')(() => ({
  overflowY: 'auto',
  flex: 1,
  marginBottom: 0,
  height: '100%',
}));

type Props = {
  open: boolean;
  toggleOrgDrawer: () => void;
};

const OrgSelector = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const currentOrg = useSelector((state: AppState) => state.rms.currentOrg);

  const classes = useStyles({ drawerWidth });
  const { open, toggleOrgDrawer } = props;
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState<Org[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalOrgsCount, setTotalOrgsCount] = useState(0);
  const [currentTotalOrgsCount, setCurrentTotalOrgsCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const location = useLocation();
  const [loadingbrandsForSelectedOrg, setLoadingbrandsForSelectedOrg] = useState(false);

  useEffect(() => {
    if (location?.search.includes('orgSearch')) {
      const params = new URLSearchParams(location.search);
      const orgSearch = params.get('orgSearch');
      orgSearch && setSearchQuery(orgSearch);
    }
  }, []);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery]);

  const loadOrgs = async (page: number, query = '') => {
    setLoading(true);
    try {
      const { data } = await organisationService.getOrgs({
        page,
        pageSize: query ? 25 : 20,
        idOrNameContains: query,
      });
      setLoading(false);
      setOrgs([...orgs, ...(data?.data || [])]);
      setCurrentTotalOrgsCount(data.totalCount ?? 0);

      if (!query && data.totalCount !== totalOrgsCount) {
        setTotalOrgsCount(data.totalCount ?? 0);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const getFurtherOrgs = async () => {
    const newPage = pageNumber + 1;

    setPageNumber(newPage);
    loadOrgs(newPage, searchQuery);
  };

  const handleScroll = ({ target }) => {
    const { scrollHeight, clientHeight, scrollTop } = target;

    // scroll triggers when the compoment goes from has scroll bar to no scroll bar,
    // if there's no scrollbar, we should not fetch more apps.
    // However this hasScrollBar has its problem, see comment from this https://stackoverflow.com/a/5038256
    // it works for us now because we do not hide the overflow
    const hasScrollBar = scrollHeight > clientHeight;
    const scrollTopMax = scrollHeight - clientHeight;

    if (
      !loading &&
      hasScrollBar &&
      pageNumber * limit < currentTotalOrgsCount &&
      Math.ceil(scrollTop) >= scrollTopMax
    ) {
      getFurtherOrgs();
    }
  };

  const handleSearch = useCallback(
    debounce((query: string) => {
      loadOrgs(1, query);
      setPageNumber(1);
    }, 500),
    []
  );

  return (
    <Drawer
      variant={matches ? 'permanent' : 'temporary'}
      anchor="right"
      classes={{
        root: classes.root,
        paper: clsx({
          [classes.drawer]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      PaperProps={{ elevation: open ? 10 : 0 }}
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          toggleOrgDrawer();
        }
      }}
      ref={ref}
    >
      {currentOrg && (
        <OrgSelectorItem
          dataFd={`org-selector-${currentOrg?.orgId}`}
          imgUrl={currentOrg?.logoImageUrl}
          imgName={currentOrg?.name}
          primary={currentOrg?.name}
          secondary={currentOrg?.orgId}
          isHighlighted
        />
      )}
      {(totalOrgsCount > 8 || searchQuery !== '') && (
        <OrgSelectorSearch {...{ setSearchQuery, searchQuery, open }} />
      )}
      {open &&
        (loading || loadingbrandsForSelectedOrg ? (
          <div className={classes.loadingContainer}>
            <AppLoadingCircularProgress />
          </div>
        ) : (
          <ListContainer onScroll={handleScroll}>
            <OrgSelectorItems
              setSearchQuery={setSearchQuery}
              setLoadingbrandsForSelectedOrg={setLoadingbrandsForSelectedOrg}
              orgs={orgs}
              toggleOrgDrawer={toggleOrgDrawer}
              open={open}
              searchQuery={searchQuery}
            />
          </ListContainer>
        ))}
      <OrgCreateAdminBtn toggleOrgDrawer={toggleOrgDrawer} />
    </Drawer>
  );
});

export default OrgSelector;
