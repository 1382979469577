import { RouteNames } from '../routes/routeNames';

/**
 * @description This allows generic links like http://portal.flipdish.com/reports/dashboards to be transformed to http://portal.flipdish.com/fd12345/reports/dashboards
 * @param knownRoutes - array of known routes
 */
export const ensureAppIdInUrlPath = () => {
  const locationUrl = new URL(window.location.href);
  const currentAppId = localStorage.getItem('fd-currentAppId') ?? '';

  if (locationUrl.pathname.includes(currentAppId) || !currentAppId) {
    return;
  }

  const isKnownRoute = Object.values(routeNamesMap).find((friendlyRouteName) =>
    locationUrl.pathname.startsWith(`/${friendlyRouteName}`)
  );

  if (locationUrl.pathname && currentAppId && isKnownRoute) {
    const pathSegments = locationUrl.pathname.split('/');
    pathSegments.splice(1, 0, currentAppId);
    locationUrl.pathname = pathSegments.join('/');

    const newLocation = locationUrl.toString();
    window.location.replace(newLocation);
  }
};

export const routeNamesMap: Record<RouteNames, string> = {
  finance: 'finance',
  billing: 'billing',
  banking: 'banking',
  home: 'home',
  orders: 'orders',
  menus: 'menus',
  products: 'products',
  'modifier-groups': 'modifier-groups',
  profile: 'profile',
  storegroups: 'storegroups',
  teammates: 'teammates',
  telephony: 'telephony',
  vouchers: 'vouchers',
  campaigns: 'campaigns',
  logs: 'logs',
  'logs.id': 'logs.id',
  developers: 'developers',
  'sales-channels': 'sales-channels',
  website: 'website',
  push_notifications: 'push_notifications',
  terminals: 'terminals',
  appstore: 'appstore',
  orderFulfillmentStatus: 'orderFulfillmentStatus',
  reports: 'reports',
  'reports.rms': 'reports.rms',
  'reports.customers': 'customers',
  settings: 'settings',
  'portal.redirect_index': 'portal.redirect_index',
  inventory: 'inventory',
  entitlements: 'entitlements',
  documents: 'documents',
  signage: 'signage',
  accounts: 'accounts',
  payroll: 'payroll',
  foodSafety: 'foodSafety',
  menu_editor: 'menu_editor',
  'micro-frontend-example': 'micro-frontend-example',
  sales: 'sales',
  properties: 'properties',
  marketing: 'marketing',
  'flipdish-phone-marketing': 'flipdish-phone-marketing',
  centralKitchen: 'central-kitchen',
};
