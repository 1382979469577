import React, { useCallback, useEffect, useState } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import { StoreOrderNotificationContactDetails } from '@flipdish/api-client-typescript/private/api';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import {
  deleteStoreOrderNotificationContacts,
  getStoreOrderNotificationContacts,
  setStoreOrderNotificationContacts,
} from '../../../actions/store.actions';
import { notify, NotifyProps } from '../../../layouts/Notify/actions';
import {
  getSelectedStore,
  getStoreOrderNotificationsEmailSelector,
  getStoreOrderNotificationsSMSSelector,
} from '../../../selectors/store.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import EmailOrderNotificationsForm from './EmailOrderNotificationsForm';
import { RemoveNotificationDialog } from './RemoveNotificationDialog';
import SmsOrderNotificationsForm from './SmsOrderNotificationsForm';

type Props = MappedState & MappedDispatch;
const StoreOrderNotifications = ({
  deleteNotification,
  emailOrderNotifications,
  getNotifications,
  addNotification,
  smsOrderNotifications,
  store,
  translate,
  notify,
}: Props) => {
  useLoadStoreFromSalesChannelIdIntoRedux({ store });
  const [notificationToRemove, setNotificationToRemove] =
    useState<StoreOrderNotificationContactDetails>();

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_orderNotifications', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    if (store?.StoreId) {
      getNotifications(store.StoreId);
    }
  }, [store]);

  const showRemoveNotificationDialog = (notification: StoreOrderNotificationContactDetails) => {
    setNotificationToRemove(notification);
  };

  const hideRemoveNotificationDialog = () => {
    setNotificationToRemove(undefined);
  };

  const backToParent = useCallback(({ history }: any) => {
    const parent = history.location.pathname.replace('/ordernotifications', '');
    history.push(parent);
  }, []);

  return (
    <PageLayout
      documentTitle="Order_notifications"
      title={translate('Order_notifications')}
      toParent={backToParent}
      caption={store?.Name || ''}
      strictToParent
    >
      <PaperContainer fluid>
        <SmsOrderNotificationsForm
          deleteNotification={deleteNotification}
          smsOrderNotifications={smsOrderNotifications}
          submit={addNotification}
          StoreId={store?.StoreId}
          onRemove={showRemoveNotificationDialog}
          translate={translate}
          notify={notify}
        />
        <EmailOrderNotificationsForm
          deleteNotification={deleteNotification}
          emailOrderNotifications={emailOrderNotifications}
          submit={addNotification}
          StoreId={store?.StoreId}
          translate={translate}
          onRemove={showRemoveNotificationDialog}
          notify={notify}
        />
      </PaperContainer>

      <RemoveNotificationDialog
        open={!!notificationToRemove}
        storeId={Number(store?.StoreId)}
        orderNotification={notificationToRemove!}
        onRemove={hideRemoveNotificationDialog}
        onCancel={hideRemoveNotificationDialog}
      />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    translate: getTranslate(state),
    store: getSelectedStore(state),
    smsOrderNotifications: getStoreOrderNotificationsSMSSelector(state),
    emailOrderNotifications: getStoreOrderNotificationsEmailSelector(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    notify: (data: NotifyProps) => dispatch(notify(data)),
    getNotifications: (storeId: number) => dispatch(getStoreOrderNotificationContacts(storeId)),
    addNotification: (storeId: number, details: StoreOrderNotificationContactDetails) =>
      dispatch(setStoreOrderNotificationContacts(storeId, details)),
    deleteNotification: (storeId: number, details: StoreOrderNotificationContactDetails) =>
      dispatch(deleteStoreOrderNotificationContacts(storeId, details)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StoreOrderNotifications);
