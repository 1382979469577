import React from 'react';

import { type RouteComponentProps, Route, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import ErrorBoundary from '../../layouts/Portal/ErrorBoundary';
import RMSPage from '../RMS/components/RMSPage';
import SalesChannelRoutes from '../RMS/SalesChannels/SalesChannelRoutes';
import { RMSModules } from '../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../RMS/utils/rmsSubscriptions';
import CreateAndEditProperty from './components/CreateAndEditProperty';
import PropertiesList from './PropertiesList';
import PropertyDetails from './PropertyDetails';
import PropertyGeneralSettingsPage from './PropertyGeneralSettingsPage';
import PropertySettingsRoutes from './propertySettings.routes';

type RouteParams = {
  appId?: string;
};

type Props = RouteComponentProps<RouteParams>;

const PropertiesRoutes: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    match: { path },
  } = props;

  return (
    <ErrorBoundary identifier="properties">
      <Switch>
        <Route exact path={`${path}`} component={PropertiesList} />
        <Route path={`${path}/:propertyId/sales-channel`} component={SalesChannelRoutes} />

        <Route exact path={`${path}/create`} component={CreateAndEditProperty} />
        <Route exact path={`${path}/:propertyId`} component={PropertyDetails} />
        <Route
          exact
          path={`${path}/:propertyId/general-settings`}
          component={PropertyGeneralSettingsPage}
        />
        <Route
          exact
          path={`${path}/:propertyId/devices`}
          render={() => (
            <LazyComponent>
              <RMSPage
                toParent="./"
                subscription={RMSSubscriptions.BASE}
                title={'Devices'}
                module={RMSModules.STATIONS}
                url={`configurations/system/${RMSModules.STATIONS}`}
                propertyIdFromUrl
              />
            </LazyComponent>
          )}
        />
        <Route path={`${path}/:propertyId`} component={PropertySettingsRoutes} />
      </Switch>
    </ErrorBoundary>
  );
};

export default PropertiesRoutes;
