import React, { useState } from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import IntercomIcon from '@mui/icons-material/ChatOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import SupportIcon from '@mui/icons-material/OpenInNewOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { flagService } from '../../services/flagService';
import { getSupportLanguageUrl } from './HelpMenu';

const useStyles = makeStyles((theme: Theme) => ({
  collapseRoot: {
    position: 'absolute',
    top: '65px',
    left: 0,
    width: '100%',
    backgroundColor: 'white',
    zIndex: 10,
  },
  iconGrid: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'none',
    justifyContent: 'left',
  },
  textStyles: {
    textDecoration: 'none',
    fontSize: '16px',
    paddingLeft: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.87)',
  },
  aLinkStyle: {
    textDecoration: 'none',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
}));

type OuterProps = {
  tutorialIsOpen?: boolean;
  setTutorialIsOpen: (boolean) => void;
};

type Props = OuterProps & MappedState;

const TopNavMobileExpandedMenu = (props: Props) => {
  const { language, showOpenRMSButton, appId } = props;
  const classes = useStyles();

  const [expanded, setExpanded] = useState<boolean>(false);

  const rmsData = useQueryRMSCommonDataHook(appId ?? '');
  let franchisorId = '';

  if (rmsData.data?.associations) {
    franchisorId = rmsData.data.associations?.orgHierarchy.franchisor.id;
  }

  const rmsUrl = window.location.host.includes('portal.flipdish.com')
    ? `https://rms.portal.flipdish.com?franchisorId=${franchisorId}`
    : `https://rms.portal.flipdishdev.com?franchisorId=${franchisorId}`;

  return (
    <>
      <IconButton
        aria-label="toggle-top-nav-menu-expand"
        data-fd="toggle-top-nav-menu-expand-button"
        component={'button'}
        size="medium"
        onClick={() => setExpanded(!expanded)}
      >
        {!expanded && <KeyboardArrowDown />}
        {expanded && <KeyboardArrowUp />}
      </IconButton>

      <Collapse
        in={expanded}
        className={classes.collapseRoot}
        collapsedSize={expanded ? '72px' : undefined}
        component="div"
      >
        <Grid container>
          {/* HIDING Tutorial until work is done to update its api calls etc CLX-1508 */}
          {/* <Grid item xs={12} className={classes.iconGrid}>
            <a
              className={classes.aLinkStyle}
              aria-label="link-to-flipdish-tutorial"
              onClick={() => {
                setTutorialIsOpen(!tutorialIsOpen);
              }}
            >
              <IconButton>
                <img src={checklistIcon} alt="checklist-icon-for-tutorial-button" />
              </IconButton>
              <Typography variant="button" className={classes.textStyles}>
                <Translate id="Tutorial" />
              </Typography>
            </a>
          </Grid> */}
          <Grid item xs={12} className={classes.iconGrid}>
            <a
              className={classes.aLinkStyle}
              aria-label="link-to-flipdish-support"
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
              target="_blank"
              href={getSupportLanguageUrl(language)}
              rel="noopener noreferrer"
            >
              <IconButton>
                <ContactSupportOutlinedIcon />
              </IconButton>
              <Typography variant="button" className={classes.textStyles}>
                <Translate id="Flipdish_support_guide" />
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12} className={classes.iconGrid}>
            <a
              className={classes.aLinkStyle}
              aria-label="link-to-flipdish-contact-us"
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
              target="_blank"
              href="https://help.flipdish.com/s/contactsupport?language=en_GB"
              rel="noopener noreferrer"
            >
              <IconButton>
                <IntercomIcon />
              </IconButton>
              <Typography variant="button" className={classes.textStyles}>
                <Translate id="Contact_us" />
              </Typography>
            </a>
          </Grid>
          <Grid item xs={12} className={classes.iconGrid}>
            <a
              className={classes.aLinkStyle}
              aria-label="link-to-flipdish-academy"
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(!expanded);
              }}
              target="_blank"
              href="https://academy.flipdish.com/"
              rel="noopener noreferrer"
            >
              <IconButton>
                <SchoolOutlinedIcon />
              </IconButton>
              <Typography variant="button" className={classes.textStyles}>
                <Translate id="Flipdish_Academy" />
              </Typography>
            </a>
          </Grid>
          {showOpenRMSButton && !!rmsData.data?.subscriptions?.length && (
            <Grid item xs={12} className={classes.iconGrid}>
              <a
                className={classes.aLinkStyle}
                aria-label="link-to-flipdish-rms"
                onClick={(e) => {
                  e.stopPropagation();
                  setExpanded(!expanded);
                }}
                target="_blank"
                href={rmsUrl}
                rel="noopener noreferrer"
              >
                <IconButton>
                  <SupportIcon />
                </IconButton>
                <Typography variant="button" className={classes.textStyles}>
                  {<Translate id="Open_RMS_Portal" />}
                </Typography>
              </a>
            </Grid>
          )}
        </Grid>

        <Divider />
      </Collapse>
    </>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  language: state.account.Language,
  showOpenRMSButton: flagService.isFlagOn(state, 'showRMS'),
  appId: state.currentApp?.AppId,
});

export default connect(mapStateToProps)(TopNavMobileExpandedMenu);
