import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import * as FlipdishApi from '@flipdish/api-client-typescript';
import BankIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import FinanceIcon from '@mui/icons-material/AttachMoneyOutlined';
import BarChartIcon from '@mui/icons-material/BarChartOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CodeIcon from '@mui/icons-material/Code';
import DeveloperIcon from '@mui/icons-material/CodeOutlined';
import CreditCard from '@mui/icons-material/CreditCard';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import WebsiteIcon from '@mui/icons-material/LanguageOutlined';
import AuditLogsIcon from '@mui/icons-material/ListOutlined';
import VouchersIcon from '@mui/icons-material/LoyaltyOutlined';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import TeammatesIcon from '@mui/icons-material/PeopleOutlined';
import PermPhoneMsgOutlined from '@mui/icons-material/PermPhoneMsgOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import DevicesIcon from '@mui/icons-material/PhonelinkSetup';
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import RestaurantOutlined from '@mui/icons-material/RestaurantOutlined';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import OrdersIcon from '@mui/icons-material/ShoppingCartOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import StoresIcon from '@mui/icons-material/StoreOutlined';
import TvOutlinedIcon from '@mui/icons-material/TvOutlined';

import appStoreIcon from '../../assets/images/Appstore.svg';
import campaignsIcon from '../../assets/images/campaigns.svg';
import { ReactComponent as CatalogIcon } from '../../assets/images/catalog.svg';
import generalSettingsIcon from '../../assets/images/General_settings.svg';
import customersIcon from '../../assets/images/ico_customers.svg';
import ledgerIcon from '../../assets/images/Ledger.svg';
import payoutsIcon from '../../assets/images/Payouts.svg';
import { ReactComponent as ProductIcon } from '../../assets/images/Products.svg';
import salesChannelsIcon from '../../assets/images/sales-channels-icon.svg';
import EntitlementsIcon from '../../assets/images/StickyNote.svg';
import { KioskIcon } from '../../components/Kiosks/Components/KioskNestedMenuIcon';
import { MenuSource } from '../../components/RMS/rms.services';
import { RMSSubscriptions } from '../../components/RMS/utils/rmsSubscriptions';
import GeneralSettingsIcon from '../../components/Settings/components/GeneralSettingsIcon';
import { store } from '../../helpers/store';
import { flagService } from '../../services/flagService/flagService';

export type ItemMetaData = {
  hidden?: boolean;
  icon: JSX.Element;
  iconAnimated?: JSX.Element;
  isBeta?: boolean;
  path: string;
  permissions?: AppState['permissions'];
  step?: number;
  title: TranslationId;
  subItems?: Array<{
    title: TranslationId;
    path: string;
    icon?: React.ReactElement;
    hidden?: boolean;
    isBeta?: boolean;
    keepQuery?: boolean;
    permissions?: AppState['permissions'];
  }>;
  subDrawerItems?: Array<{
    title: TranslationId;
    path: string;
    icon?: React.ReactElement;
    hidden?: boolean;
    isBeta?: boolean;
    keepQuery?: boolean;
    permissions?: AppState['permissions'];
  }>;
};

export const menuItemsConfig = (
  currentApp: FlipdishApi.App,
  isProductBasedMenusOn: boolean,
  menuSource?: MenuSource,
  subscriptions = Array<RMSSubscriptions>()
): ItemMetaData[] => {
  const { AppId: currentAppId, Features, CountryId } = currentApp;
  const { data } = useQueryRMSCommonDataHook(currentAppId ?? '');
  const state = store.getState();

  const isApiFlagOn = data?.associations?.isMigratedToSalesChannels;

  /**
   * If apiFlag is off, override by adding appId to showSalesChannelStructure FF in Split.
   *
   * e.g api says hide it, then add the appId to the FF in Split to show it
   * */
  const showSalesChannelStructure =
    isApiFlagOn || flagService.isFlagOn(state, 'showSalesChannelStructure');

  /**
   * hideStoresPage2 FF defaults to true. To show stores page, add the appId to the FF in Split.
   *
   * e.g the api says hide the stores page but we want to show it, then add the appId to the FF in Split
   */
  const hideStoresPage = isApiFlagOn && flagService.isFlagOn(state, 'hideStoresPage2');

  const showMenuEditor = menuSource !== 'Flipdish' || flagService.isFlagOn(state, 'showMenuEditor');

  const showPayGreen = flagService.isFlagOn(store.getState(), 'paygreenFeatures');
  const showSmsCampaigns = flagService.isFlagOn(store.getState(), 'showSmsCampaigns');
  const showFpm2024 = flagService.isFlagOn(store.getState(), 'showPhoneMarketing');

  const paygreenCountries = ['FR', 'BE'];
  const links: ItemMetaData[] = [
    {
      title: 'Home',
      path: `/${currentAppId}/home`,
      icon: <HomeIcon />,
    },
    {
      title: 'Orders',
      path: `/${currentAppId}/orders`,
      icon: <OrdersIcon />,
      permissions: ['ViewOrders'],
    },
    {
      title: 'Sales',
      path: `/${currentAppId}/sales`,
      icon: <ReceiptIcon />,
      permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
      hidden: !subscriptions.includes(RMSSubscriptions.BASE),
    },
    {
      title: 'Properties',
      path: `/${currentAppId}/properties`,
      icon: <StoresIcon />,
      permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
      hidden: !showSalesChannelStructure,
    },
    {
      title: 'Menus',
      path: `/${currentAppId}/menus`,
      icon: <RestaurantOutlined />,
      hidden: menuSource === 'RMS',
      permissions: ['ViewMenu'],
    },
    {
      title: 'Menu_Editor',
      path: `/${currentAppId}/menu-editor`,
      icon: <RestaurantOutlined />,
      hidden: !showMenuEditor,
      permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
    },
    {
      title: 'Reports',
      path: `/${currentAppId}/reports/dashboards`,
      icon: <BarChartIcon />,
    },
    {
      title: 'Catalog',
      path: '',
      icon: <CatalogIcon />,
      isBeta: true,
      hidden: !isProductBasedMenusOn,
      subItems: [
        {
          title: 'Products',
          path: `/${currentAppId}/products`,
          hidden: !isProductBasedMenusOn,
          icon: <ProductIcon color="rgb(108, 107, 107)" />,
          isBeta: true,
        },
        {
          title: 'Modifier_Groups',
          path: `/${currentAppId}/modifier-groups`,
          hidden: !isProductBasedMenusOn,
          icon: <ProductIcon color="rgb(108, 107, 107)" />,
          isBeta: true,
        },
      ],
    },
    {
      title: 'Marketing',
      path: '',
      icon: <CampaignOutlinedIcon />,
      subItems: [
        {
          title: 'Customers',
          path: `/${currentAppId}/customers`,
          icon: <img src={customersIcon} alt="Customers" />,
          isBeta: false,
          permissions: ['ViewCustomerStatistics'],
        },
        {
          title: 'Vouchers',
          path: `/${currentAppId}/vouchers`,
          icon: <VouchersIcon />,
          permissions: ['ViewVouchers'],
        },
        {
          title: 'Campaigns',
          path: `/${currentAppId}/campaigns`,
          icon: <img src={campaignsIcon} />,
          permissions: ['ViewCampaignsConfigurations'],
          isBeta: false,
          hidden: Features?.includes('disable-campaign'),
        },
        {
          title: 'Push_Notifications',
          path: `/${currentAppId}/push_notifications`,
          icon: <NotificationsIcon />,
        },
        {
          title: 'Flipdish_Phone_Marketing',
          path: `/${currentAppId}/telephony/dashboards/global/flipdish_phone_marketing_fpm_report`,
          icon: <PermPhoneMsgOutlined />,
        },
        {
          title: 'Flipdish_Phone_Marketing_beta',
          path: `/${currentAppId}/flipdish-phone-marketing/home`,
          icon: <PermPhoneMsgOutlined />,
          permissions: ['FlipdishStaff'],
          hidden: !showFpm2024,
        },
        {
          title: 'SMS_campaigns',
          path: `/${currentAppId}/marketing/campaigns`,
          icon: <img src={campaignsIcon} alt="SMS Campaigns" />,
          hidden: !showSmsCampaigns,
          isBeta: true,
          permissions: ['FlipdishStaff'],
        },
      ],
    },
    {
      title: 'Payouts',
      path: `/${currentAppId}/finance`,
      icon: <FinanceIcon />,
      permissions: ['ViewBankAccounts', 'FlipdishStaff'],
      subItems: [
        {
          title: 'Payouts',
          path: `/${currentAppId}/finance/payouts`,
          icon: <img src={payoutsIcon} />,
          isBeta: true,
        },
        {
          title: 'Bank_accounts',
          path: `/${currentAppId}/finance/bank-accounts`,
          icon: <BankIcon />,
        },
        {
          title: 'Restaurant_vouchers',
          path: `/${currentAppId}/finance/restaurant-vouchers`,
          icon: <CreditCard />,
          permissions: ['Owner', 'StoreOwner', 'ManagedOwner', 'FinanceManger'],
          hidden: !showPayGreen || !paygreenCountries.includes(CountryId || ''),
        },
        {
          title: 'Ledger',
          path: `/${currentAppId}/finance/ledger`,
          icon: <img src={ledgerIcon} />,
          hidden: Features?.includes('ledger-menu-item') ? false : true,
        },
      ],
    },
    {
      title: 'Sales_channels',
      path: `/${currentAppId}/sales-channels`,
      icon: <img src={salesChannelsIcon} />,
      permissions: ['ViewSalesChannels', 'EditSalesChannels', 'ViewHydraConfig'],
      subItems: [
        {
          title: 'Point_of_Sale',
          path: `/${currentAppId}/sales-channels/point-of-sale`,
          icon: <TvOutlinedIcon />,
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
          hidden: !subscriptions.includes(RMSSubscriptions.BASE),
        },
        {
          title: 'Mobile_apps',
          path: `/${currentAppId}/sales-channels/mobile-apps`,
          icon: <PhoneIphoneIcon />,
          permissions: ['ViewSalesChannels', 'EditSalesChannels'],
        },
        {
          title: 'Website',
          path: `/${currentAppId}/sales-channels/website`,
          icon: <WebsiteIcon />,
          permissions: ['EditSalesChannels'],
        },
        {
          title: 'Kiosks',
          path: `/${currentAppId}/sales-channels/kiosk`,
          icon: <KioskIcon />,
          permissions: ['ViewHydraConfig'],
        },
        {
          title: 'Marketplaces',
          path: `/${currentAppId}/sales-channels/marketplaces`,
          icon: <StorefrontOutlinedIcon />,
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
          hidden: !showSalesChannelStructure,
        },
      ],
    },
    {
      title: 'Stores',
      path: `/${currentAppId}/storegroups`,
      icon: <StoresIcon />,
      hidden: hideStoresPage,
    },
    {
      title: 'Settings',
      path: '',
      icon: <GeneralSettingsIcon />,
      permissions: ['Owner', 'ManagedOwner'],
      subDrawerItems: [
        {
          title: 'Organization',
          path: `/${currentAppId}/settings/organisation`,
          icon: <RoomPreferencesOutlinedIcon />,
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Brand',
          path: `/${currentAppId}/settings/brands`,
          icon: <img src={generalSettingsIcon} />,
        },
        {
          title: 'Teammates',
          path: `/${currentAppId}/teammates`,
          icon: <TeammatesIcon />,
          permissions: ['EditTeammates', 'ViewTeammates'],
        },
        {
          title: 'Metafields',
          path: `/${currentAppId}/settings/metafields`,
          icon: <CodeIcon />,
          hidden: !isProductBasedMenusOn,
        },
        {
          title: 'Sales_channels',
          path: `/${currentAppId}/settings/sales-channels`,
          icon: <img src={generalSettingsIcon} />,
          permissions: ['ViewSalesChannels', 'EditSalesChannels'],
        },
        {
          title: 'Sales_channel_groups',
          path: `/${currentAppId}/settings/sales-channel-groups`,
          icon: <img src={generalSettingsIcon} />,
          permissions: ['ViewSalesChannels', 'EditSalesChannels'],
          hidden: !showSalesChannelStructure,
        },
        {
          title: 'Customer_messages',
          path: `/${currentAppId}/settings/customer-messages/`,
          icon: <img src={generalSettingsIcon} />,
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
          hidden: !showSalesChannelStructure,
        },
        {
          title: 'Entitlements',
          path: `/${currentAppId}/entitlements`,
          icon: <img src={EntitlementsIcon} />,
          permissions: ['FlipdishStaff', 'ShowHiddenFeatures'],
        },
        {
          title: 'Audit_logs',
          path: `/${currentAppId}/logs`,
          icon: <AuditLogsIcon />,
          permissions: [
            'ViewAppAuditLogs',
            'ViewBankAccountAuditLogs',
            'ViewCampaignsAuditLogs',
            'ViewCustomerAuditLogs',
            'ViewFeeConfigurationsAuditLogs',
            'ViewHydraAuditLogs',
            'ViewMenuAuditLogs',
            'ViewOrdersAuditLogs',
            'ViewPrinterAuditLogs',
            'ViewPushNotificationAuditLogs',
            'ViewStoreAuditLogs',
            'ViewTeammatesAuditLogs',
            'ViewUserEventsAuditLogs',
            'ViewVouchersAuditLogs',
            'ViewApmAuditLogs',
          ],
        },
      ],
    },
    {
      title: 'Add_ons',
      path: 'Add_ons',
      icon: <KeyboardArrowDown />,
      iconAnimated: <KeyboardArrowUp />,
      subItems: [
        {
          title: 'App_Store',
          path: `/${currentAppId}/appstore`,
          icon: <img src={appStoreIcon} />,
          permissions: ['Owner'],
        },
        {
          title: 'Accounts',
          path: `/${currentAppId}/add-ons/accounts`,
          icon: <AccountBalanceWalletOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.ACCOUNTS),
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Documents',
          path: `/${currentAppId}/add-ons/documents`,
          icon: <PlagiarismOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.DOCUMENTS),
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'FoodSafety',
          path: `/${currentAppId}/add-ons/food-safety`,
          icon: <PlagiarismOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.FOOD_SAFETY),
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Inventory',
          path: `/${currentAppId}/add-ons/inventory`,
          icon: <AssignmentIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.INVENTORY),
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Payroll',
          path: `/${currentAppId}/add-ons/payroll`,
          icon: <GroupsOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.PAYROLL),
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Signage',
          path: `/${currentAppId}/add-ons/signage`,
          icon: <OndemandVideoOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.SIGNAGE),
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Central_kitchen',
          path: `/${currentAppId}/add-ons/central-kitchen`,
          icon: <HubOutlinedIcon />,
          hidden: !subscriptions.includes(RMSSubscriptions.CENTRAL_KITCHEN),
          permissions: ['Owner', 'ManagedOwner', 'FlipdishStaff'],
        },
        {
          title: 'Terminals',
          path: `/${currentAppId}/terminals`,
          icon: <DevicesIcon />,
          permissions: ['ViewHydraConfig'],
        },
      ],
    },
  ];

  return links;
};

export const menuItemsConfigMobile = (currentAppId: string | undefined): ItemMetaData[] => {
  return [
    {
      title: 'My_account',
      path: `/${currentAppId}/profile`,
      icon: <PersonIcon />,
    },
    {
      title: 'developer_tools',
      path: `/${currentAppId}/developers`,
      icon: <DeveloperIcon />,
    },
  ];
};
