import React, { useCallback, useEffect, useState } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import { PreOrderConfig, StoreOrderCapacityConfig } from '@flipdish/api-client-typescript';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';

import {
  useErrorAsIndicator,
  useLoadingAsIndicator,
} from '../../../../custom-hooks/useAsIndicator';
import useRequestSnackBar from '../../../../custom-hooks/useRequestSnackBar';
import { orderCapacityFeature } from '../../../../selectors/app.selector';
import { getSelectedStore } from '../../../../selectors/store.selector';
import { useTracking } from '../../../../services/amplitude/useTracking';
import { updateStoreOrderCapacity } from '../../../../services/store.service';
import EmptyComponent from '../../../../ui/EmptyComponent';
import PageLayout from '../../../../ui/Layout';
import GridContainer from '../../../../ui/Layout/GridContainer';
import PaperContainer from '../../../../ui/Layout/PaperContainer';
import LoadingButton from '../../../../ui/LoadingButton';
import {
  GET_PRE_ORDER_COLLECTION_SETTINGS,
  GET_PRE_ORDER_DELIVERY_SETTINGS,
  getPreOrderCollectionSettings,
  getPreOrderDeliverySettings,
  SET_PRE_ORDER_SETTINGS_STATUS,
  setPreOrderSettingsStatus,
  UPDATE_PRE_ORDER_COLLECTION_SETTINGS,
  UPDATE_PRE_ORDER_DELIVERY_SETTINGS,
  updatePreOrderCollectionSettings,
  updatePreOrderDeliverySettings,
} from '../actions';
import { canEditOrderCapacity } from '../selectors';
import PreOrderSettingsDisableDialog from './PreOrderSettingsDisableDialog';
import PreOrderSettingsForm from './PreOrderSettingsForm';
import PreOrderSettingsSkeleton from './PreOrderSettingsSkeleton';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  textWrapper: {
    padding: `${spacing(2)} 0 36px`,
  },
  text: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

type MapStateToProps = ReturnType<typeof mapStatePropsFactory>;
const mapStatePropsFactory = (state: AppState, ownProps: OuterProps) => {
  const { appId, type: deliveryType } = ownProps.match.params;
  const canEdit = canEditOrderCapacity(state);
  const store = getSelectedStore(state) as any;
  const isDelivery = deliveryType === 'delivery';
  const loadingFieldName = isDelivery
    ? GET_PRE_ORDER_DELIVERY_SETTINGS
    : GET_PRE_ORDER_COLLECTION_SETTINGS;
  const autoSavingFieldName = isDelivery
    ? UPDATE_PRE_ORDER_DELIVERY_SETTINGS
    : UPDATE_PRE_ORDER_COLLECTION_SETTINGS;
  const data = isDelivery
    ? state.preOrderSettings.deliverySettings
    : state.preOrderSettings.collectionSettings;

  return {
    data,
    store,
    canEdit,
    loading: state.loading[loadingFieldName],
    translate: getTranslate(state.locale),
    autoSaving: state.loading[autoSavingFieldName],
    orderCapacityFeature: orderCapacityFeature(state),

    disabled: store
      ? isDelivery
        ? !store.PreOrderDeliveryEnabled
        : !store.PreOrderPickupEnabled
      : true,
    error: state.preOrderSettings.error ? state.preOrderSettings.error : undefined,
    disabling: state.loading[SET_PRE_ORDER_SETTINGS_STATUS],
    toggling: state.loading[SET_PRE_ORDER_SETTINGS_STATUS],
    type: deliveryType,
    appId,
  };
};

type MapDispatchToProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  {
    match: {
      params: { type },
    },
  }
) => {
  const isDelivery = type === 'delivery';

  return {
    getSettings: (storeId) => {
      if (isDelivery) {
        dispatch(getPreOrderDeliverySettings(storeId));
        return;
      }
      dispatch(getPreOrderCollectionSettings(storeId));
    },
    saveSettings: (data: PreOrderConfig, storeId) => {
      if (isDelivery) {
        dispatch(updatePreOrderDeliverySettings(storeId, data));
        return;
      }
      dispatch(updatePreOrderCollectionSettings(storeId, data));
    },
    setStatus: (status: boolean, storeId) => {
      dispatch(setPreOrderSettingsStatus(storeId, isDelivery ? 'Delivery' : 'Pickup', status));
    },
  };
};

export type SettingsType = 'delivery' | 'collection';

type InnerProps = MapStateToProps & MapDispatchToProps;

type OuterProps = RouteComponentProps<{
  appId: string;
  storeGroupId: string;
  storeId: string;
  type: 'delivery' | 'collection';
}>;

type Props = InnerProps & OuterProps & RouteComponentProps;

export default compose<InnerProps, OuterProps>(
  withRouter,
  connect(mapStatePropsFactory, mapDispatchToProps)
)(({
  appId,
  type,
  store,
  autoSaving,
  translate,
  disabled,
  data,
  loading,
  canEdit,
  getSettings,
  saveSettings,
  setStatus,
  toggling,
  error,
  disabling,
  orderCapacityFeature,
}: Props) => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const classes = useStyles();

  const failure = useErrorAsIndicator(error);
  const successAutoSaving = useLoadingAsIndicator(autoSaving);
  const success = successAutoSaving && !error;
  const { storeId } = useLoadStoreFromSalesChannelIdIntoRedux({ store });

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_preOrderSettings', {
      action: 'logged_in',
    });
  }, []);

  useRequestSnackBar({
    success,
    failure,
    autoSaving,
    failureMessage: error,
  });

  const handleDisable = useCallback(() => {
    setStatus(false, storeId);
    setDialogOpen(false);
  }, []);

  const handleEnable = useCallback(() => {
    setStatus(true, storeId);
  }, []);

  useEffect(() => {
    if (!disabled) {
      getSettings(storeId);
    }
  }, [disabled]);

  const handleSubmit = useCallback(
    (data: PreOrderConfig) => {
      saveSettings(data, storeId);
    },
    [storeId]
  );
  const handleSubmitOrderCapacity = useCallback(
    (data: StoreOrderCapacityConfig, onSuccess: () => void) => {
      // TODO move to saga
      updateStoreOrderCapacity({
        appId,
        storeId: data.StoreId!,
        orderCapacity: data,
        deliveryType: type === 'collection' ? 'Pickup' : 'Delivery',
      })
        // TEMPORARY
        .then(onSuccess)
        .catch((error) => {
          // TODO notification
          console.error(error);
        });
    },
    [appId, storeId, type]
  );

  const backToParent = useCallback(({ history }: any) => {
    const parent = history.location.pathname.replace(
      history.location.pathname?.includes('collection') ? '/collection' : '/delivery',
      ''
    );
    history.push(parent);
  }, []);

  return (
    <PageLayout
      strictToParent
      toParent={backToParent}
      caption={(store && store.Name) || ''}
      title={translate(type === 'delivery' ? 'Preorder_for_delivery' : 'Preorder_for_collection')}
    >
      {data === false ? (
        <EmptyComponent title="Whoops" subtitle="Custom_pre_order_settings_message" noLink />
      ) : (
        <PaperContainer>
          {loading || !store ? <PreOrderSettingsSkeleton /> : null}
          {disabled && store ? (
            <div className={classes.textWrapper}>
              <Typography className={classes.text} variant="body1">
                {translate('Disabled_preordering_message')}
              </Typography>
              <LoadingButton
                loading={toggling}
                variant="contained"
                color="primary"
                disabled={!canEdit}
                fdKey="enable-pre-ordering"
                onClick={handleEnable}
              >
                {translate('Enable')}
              </LoadingButton>
            </div>
          ) : data && store ? (
            <GridContainer>
              <Grid item md={12} className={classes.gridItem}>
                <PreOrderSettingsForm
                  appId={appId}
                  storeId={storeId?.toString()}
                  type={type}
                  data={data}
                  orderCapacityFeature={orderCapacityFeature}
                  onSubmit={handleSubmit}
                  onSubmitOrderCapacity={handleSubmitOrderCapacity}
                  loading={loading || autoSaving}
                  disabling={disabling}
                  canEdit={canEdit}
                  onDisable={() => setDialogOpen(true)}
                />
              </Grid>
            </GridContainer>
          ) : null}
          <PreOrderSettingsDisableDialog
            isLoading={toggling}
            isDialogOpen={isDialogOpen}
            setDialogOpen={setDialogOpen}
            submit={handleDisable}
            type={type}
          />
        </PaperContainer>
      )}
    </PageLayout>
  );
});
