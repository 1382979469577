import React, { useCallback, useEffect } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import isEmpty from 'lodash/isEmpty';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { type RouteComponentProps } from 'react-router';

import * as openingHourActions from '../../actions/storeOpeningHours.actions';
import actionTypes from '../../constants/storeOpeningHourOverrides.constants';
import { storeOpeningHourSelectors } from '../../selectors';
import { createLoadingSelector } from '../../selectors/loading.selector';
import { getSelectedStore } from '../../selectors/store.selector';
import { useTracking } from '../../services/amplitude/useTracking';
import { StoreEvents } from '../../signalr/hub.actions';
import PageLayout from '../../ui/Layout';
import PaperContainer from '../../ui/Layout/PaperContainer';
import Permissions from '../../ui/Permissions';
import Tooltip from '../../ui/Tooltip/Tooltip';
import Calendar from './Calendar';
import ReopenStoreHeader from './components/ReopenStoreHeader';
import Title from './components/Title';
import QuickCloseMenuAction from './QuickCloseMenuAction';

/**
 * [x] loading => disabled
 * [x] store config disabled => disabled
 * [ ] store pre-order enabled => not handaling this case
 * [x] store opening hours today closed => disabled
 * [ ] store opening hours now closed but opening in 1 hour => disabled => (in 1 hour) enabled
 *
 */
type isCollectionDisabledProps = {
  loading: boolean;
  storeOpeningHours: ReturnType<typeof storeOpeningHourSelectors.store7DayOpeningHours>;
  todayOpeningHours: ReturnType<typeof storeOpeningHourSelectors.todayOpeningHours>;
};

function isCollectionDisabled({
  loading,
  storeOpeningHours,
  todayOpeningHours,
}: isCollectionDisabledProps): boolean {
  switch (true) {
    case loading:
    case isEmpty(todayOpeningHours.Pickup):
    case !storeOpeningHours || !storeOpeningHours.Pickup || !storeOpeningHours.Pickup.enabled:
      return true;
    default:
      return false;
  }
}

type isDeliveryDisabledProps = isCollectionDisabledProps;

function isDeliveryDisabled({
  loading,
  storeOpeningHours,
  todayOpeningHours,
}: isDeliveryDisabledProps): boolean {
  switch (true) {
    case loading:
    case isEmpty(todayOpeningHours.Delivery):
    case !storeOpeningHours || !storeOpeningHours.Delivery || !storeOpeningHours.Delivery.enabled:
      return true;
    default:
      return false;
  }
}

// #region ButtonWrapper
type ButtonWrapper = {
  loading: boolean;
  storeId: number;
  id: TranslationId;
  deliveryType: 'Delivery' | 'Pickup' | 'DeliveryAndPickup';
  disabled: boolean;
  preOrderEnabled?: boolean;
  preOrderPickup?: boolean;
  preOrderDelivery?: boolean;
};
const ButtonWrapper = (props: ButtonWrapper) => {
  const {
    preOrderEnabled,
    loading,
    storeId,
    id,
    deliveryType,
    disabled,
    preOrderPickup,
    preOrderDelivery,
  } = props;
  if (loading) {
    return <Skeleton width={'100%'} height={36} />;
  }

  const content = (
    <QuickCloseMenuAction
      id={id}
      deliveryType={deliveryType}
      storeId={storeId}
      disabled={disabled}
      preOrderEnabled={preOrderEnabled}
      preOrderPickup={preOrderPickup}
      preOrderDelivery={preOrderDelivery}
    />
  );

  if (disabled === true) {
    const translateId = `Closed_for_${id.toLowerCase()}` as TranslationId;
    return (
      <Tooltip messageId={translateId} fdKey={translateId}>
        <span>{content}</span>
      </Tooltip>
    );
  }

  return content;
};
// #endregion

const useStyles = makeStyles(
  (theme: Theme) => ({
    column: {
      [theme.breakpoints.up('xs')]: {
        paddingBottom: theme.spacing(3),
      },
    },
    title: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.5,
      letterSpacing: '0.2px',
      color: 'rgba(0, 0, 0, 0.6)',
      paddingBottom: '8px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
        lineHeight: 1.33,
        letterSpacing: '0.4px',
        paddingBottom: '16px',
      },
    },
    divider: {
      marginBottom: theme.spacing(2),
    },
  }),
  {
    name: 'OpeningHourOverrides',
  }
);

type AppResourceSetEnum = Required<Flipdish.App>['AppResourceSet'];
const EditStoresOpeningHoursOverrideTemporaryPermissions: AppResourceSetEnum = [
  'EditStoresOpeningHoursOverrideTemporary',
];

type Props = RouteComponentProps<{ appId: string; storeId: string; storeGroupId: string }> &
  MappedDispatch &
  MappedState;

const OpeningHourOverrides = (props: Props) => {
  const {
    loading,
    store,
    storeOpeningHours,
    preOrderEnabled,
    preOrderPickup,
    preOrderDelivery,
    todayOpeningHours,
    loadDependencies,
  } = props;
  const classes = useStyles();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const { storeId } = useLoadStoreFromSalesChannelIdIntoRedux({ store });

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_openingHoursOverrides', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    const { subscribeEvents, unsubscribeEvents } = props;

    subscribeEvents();
    return () => {
      unsubscribeEvents();
    };
  }, []);

  useEffect(() => {
    if (store?.StoreId) {
      loadDependencies();
    }
  }, [store]);

  const backToParent = useCallback(({ history }: any) => {
    const parent = history.location.pathname.replace('/openinghouroverrides', '');
    history.push(parent);
  }, []);

  const storeName = store?.Name;

  const deliveryDisabled = isDeliveryDisabled({ loading, storeOpeningHours, todayOpeningHours });
  const collectionDisabled = isCollectionDisabled({
    loading,
    storeOpeningHours,
    todayOpeningHours,
  });
  const deliveryOrCollectionDisabled = deliveryDisabled || collectionDisabled;
  return (
    <PageLayout
      key={storeId}
      caption={storeName}
      title={<Title storeId={Number(storeId)} />}
      documentTitle="Opening_hour_overrides_title"
      toParent={backToParent}
      strictToParent
      pageHeader={<ReopenStoreHeader />}
    >
      <PaperContainer>
        <Grid container spacing={0}>
          <Permissions allowed={EditStoresOpeningHoursOverrideTemporaryPermissions}>
            <Grid item xs={12} className={classes.column}>
              <Grid container spacing={isSmall ? 0 : 5}>
                <Grid item xs={12} sm={5}>
                  <Typography variant="h4" className={classes.title}>
                    <Translate id="Opening_hour_overrides_quick_close_title" />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <ButtonWrapper
                        loading={loading}
                        id="Collection"
                        deliveryType={'Pickup'}
                        storeId={Number(storeId)}
                        disabled={collectionDisabled}
                        preOrderEnabled={preOrderEnabled}
                        preOrderPickup={preOrderPickup}
                        preOrderDelivery={preOrderDelivery}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonWrapper
                        loading={loading}
                        id="Delivery"
                        deliveryType={'Delivery'}
                        storeId={Number(storeId)}
                        disabled={deliveryDisabled}
                        preOrderEnabled={preOrderEnabled}
                        preOrderPickup={preOrderPickup}
                        preOrderDelivery={preOrderDelivery}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {loading ? (
                        <Skeleton width={'100%'} height={36} />
                      ) : (
                        <QuickCloseMenuAction
                          id="Collection_and_delivery"
                          deliveryType={'DeliveryAndPickup'}
                          storeId={Number(storeId)}
                          preOrderEnabled={preOrderEnabled}
                          preOrderPickup={preOrderPickup}
                          preOrderDelivery={preOrderDelivery}
                          disabled={deliveryOrCollectionDisabled}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.divider}>
              <Divider />
            </Grid>
          </Permissions>

          <Grid item xs={12} className={classes.column}>
            <Grid container spacing={isSmall ? 0 : 5}>
              <Grid item xs={12} pb={isSmall ? 0 : 2.5}>
                <Typography variant="h4" className={classes.title}>
                  <Translate id="Opening_hour_overrides_title" />
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingTop: 0 }}>
                <Calendar
                  storeGroupId={store?.StoreGroupId as number}
                  storeId={storeId as number}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PaperContainer>
    </PageLayout>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = () => {
  const loadingSelector = createLoadingSelector([actionTypes.LOAD_ALL]);
  const autoSavingSelector = createLoadingSelector([actionTypes.CREATE, actionTypes.REMOVE]);

  return (state: AppState) => {
    const loading = loadingSelector(state);
    const autoSaving = autoSavingSelector(state);
    const store = getSelectedStore(state);
    const storeId = store?.StoreId;
    const todayOpeningHours = storeOpeningHourSelectors.todayOpeningHours(state, {
      storeId,
    });
    const preOrderEnabled = store && store.PreOrderEnabled;
    const preOrderDelivery = store && store.PreOrderDeliveryEnabled;
    const preOrderPickup = store && store.PreOrderPickupEnabled;
    const storeName = store ? store.Name : '';

    const storeOpeningHours = storeOpeningHourSelectors.store7DayOpeningHours(state, {
      storeId,
    });
    return {
      loading,
      autoSaving,
      storeName,
      preOrderEnabled,
      preOrderPickup,
      preOrderDelivery,
      store,
      storeOpeningHours,
      todayOpeningHours,
    };
  };
};

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = () => {
  return (dispatch) => {
    return {
      subscribeEvents: () => {
        dispatch(StoreEvents.Subscribe.OpeningHoursUpdated);
        dispatch(StoreEvents.Subscribe.BusinessHoursOverrideCreated);
        dispatch(StoreEvents.Subscribe.BusinessHoursOverrideDeleted);
      },
      unsubscribeEvents: () => {
        dispatch(StoreEvents.Unsubscribe.OpeningHoursUpdated);
        dispatch(StoreEvents.Unsubscribe.BusinessHoursOverrideCreated);
        dispatch(StoreEvents.Unsubscribe.BusinessHoursOverrideDeleted);
      },
      loadDependencies: () => {
        dispatch(openingHourActions.loadDelivery());
        dispatch(openingHourActions.loadPickup());
      },
    };
  };
};

export default connect(mapStateToPropsFactory, mapDispatchToPropsFactory)(OpeningHourOverrides);
