import React from 'react';

import { Translate } from 'react-localize-redux';
import Permissions from 'react-redux-permissions';
import { Route, RouteComponentProps, Switch } from 'react-router';

import LazyComponent from '@fd/ui/LazyComponent';

import { toKebabCase } from '../../../helpers/strings';
import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { RoutesForStores } from '../../Stores/Store.routes';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';
import SalesChannelSettings from './SalesChannelSettings';

const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));
const AddSalesChannel = lazyWithRetry(() => import('./AddSalesChannel'));
const EditSalesChannel = lazyWithRetry(() => import('./EditSalesChannel'));

const SalesChannelRoutes: React.FC<React.PropsWithChildren<RouteComponentProps>> = (
  props: RouteComponentProps
) => {
  const {
    match: { path },
  } = props;

  let channelLevelPath = `${path}/property/:propertyId/sales-channel/:channelId`;

  if (path.includes('properties')) {
    channelLevelPath = `${path}/:channelId`;
  }

  const baseUrl = `configurations/franchisors/properties/storefronts`;
  return (
    <ErrorBoundary identifier="Storefront-settings">
      <Switch>
        <>
          <Route
            exact
            path={`${channelLevelPath}`}
            render={() => (
              <LazyComponent>
                <SalesChannelSettings />
              </LazyComponent>
            )}
          />
          <RoutesForStores path={channelLevelPath} hideStoreDetails={true} />
          <Route
            exact
            path={`${path}/property/:propertyId/add-sales-channel`}
            render={() => (
              <LazyComponent>
                <AddSalesChannel />
              </LazyComponent>
            )}
          />
          <Route
            exact
            path={`${channelLevelPath}/edit`}
            render={() => (
              <LazyComponent>
                <EditSalesChannel />
              </LazyComponent>
            )}
          />

          <Route
            exact
            path={`${channelLevelPath}/${toKebabCase(RMSModules.OPERATING_CONFIGURATION)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Operating_Configuration" />}
                  module={RMSModules.OPERATING_CONFIGURATION}
                  url={`${baseUrl}/${RMSModules.OPERATING_CONFIGURATION}`}
                  propertyIdFromUrl
                  salesChannelIdFromUrl
                />
              </LazyComponent>
            )}
          />

          <Route
            exact
            path={`${channelLevelPath}/${toKebabCase(RMSModules.OPENING_HOURS)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Opening_Hours" />}
                  module={RMSModules.OPENING_HOURS}
                  url={`${baseUrl}/${RMSModules.OPENING_HOURS}`}
                  propertyIdFromUrl
                  salesChannelIdFromUrl
                />
              </LazyComponent>
            )}
          />

          <Route
            exact
            path={`${channelLevelPath}/${toKebabCase(RMSModules.OPENING_HOURS_OVERRIDES)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Opening_Hours_Overrides" />}
                  module={RMSModules.OPENING_HOURS_OVERRIDES}
                  url={`${baseUrl}/${RMSModules.OPENING_HOURS_OVERRIDES}`}
                  propertyIdFromUrl
                  salesChannelIdFromUrl
                />
              </LazyComponent>
            )}
          />

          <Route
            exact
            path={`${channelLevelPath}/${toKebabCase(RMSModules.DELIVERY_CHARGES)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Delivery_Charges" />}
                  module={RMSModules.DELIVERY_CHARGES}
                  url={`${baseUrl}/${RMSModules.DELIVERY_CHARGES}`}
                  propertyIdFromUrl
                  salesChannelIdFromUrl
                />
              </LazyComponent>
            )}
          />

          <Route
            exact
            path={`${channelLevelPath}/${toKebabCase(RMSModules.TIP_CONFIGURATION)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Tip_Configuration" />}
                  module={RMSModules.TIP_CONFIGURATION}
                  url={`${baseUrl}/${RMSModules.TIP_CONFIGURATION}`}
                  propertyIdFromUrl
                  salesChannelIdFromUrl
                />
              </LazyComponent>
            )}
          />
          <Permissions allowed={['FlipdishStaff']}>
            <Route
              exact
              path={`${channelLevelPath}/${toKebabCase(RMSModules.PAYOUT_CONFIGURATION)}`}
              render={() => (
                <LazyComponent>
                  <RMSPage
                    subscription={RMSSubscriptions.BASE}
                    title={<Translate id="Payout_Configuration" />}
                    module={RMSModules.PAYOUT_CONFIGURATION}
                    url={`${baseUrl}/${RMSModules.PAYOUT_CONFIGURATION}`}
                    propertyIdFromUrl
                    salesChannelIdFromUrl
                  />
                </LazyComponent>
              )}
            />
            <Route
              exact
              path={`${channelLevelPath}/${toKebabCase(RMSModules.ORDER_INGEST)}`}
              render={() => (
                <LazyComponent>
                  <RMSPage
                    subscription={RMSSubscriptions.BASE}
                    title={<Translate id="Order_Ingest" />}
                    module={RMSModules.ORDER_INGEST}
                    url={`${baseUrl}/${RMSModules.ORDER_INGEST}`}
                    propertyIdFromUrl
                    salesChannelIdFromUrl
                  />
                </LazyComponent>
              )}
            />
          </Permissions>
          <Route
            exact
            path={`${channelLevelPath}/${toKebabCase(RMSModules.TIMINGS_CONFIGURATION)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Timings_Configuration" />}
                  module={RMSModules.TIMINGS_CONFIGURATION}
                  url={`${baseUrl}/${RMSModules.TIMINGS_CONFIGURATION}`}
                  propertyIdFromUrl
                  salesChannelIdFromUrl
                />
              </LazyComponent>
            )}
          />

          <Route
            exact
            path={`${channelLevelPath}/${toKebabCase(RMSModules.OVER_PHONE_PAYMENT)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Over_Phone_Payment" />}
                  module={RMSModules.OVER_PHONE_PAYMENT}
                  url={`${baseUrl}/${RMSModules.OVER_PHONE_PAYMENT}`}
                  propertyIdFromUrl
                  salesChannelIdFromUrl
                />
              </LazyComponent>
            )}
          />

          <Route
            exact
            path={`${channelLevelPath}/${toKebabCase(RMSModules.WEB_APP_CONFIGURATION)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Web_App_Configuration" />}
                  module={RMSModules.WEB_APP_CONFIGURATION}
                  url={`${baseUrl}/${RMSModules.WEB_APP_CONFIGURATION}`}
                  propertyIdFromUrl
                  salesChannelIdFromUrl
                />
              </LazyComponent>
            )}
          />

          <Route
            exact
            path={`${channelLevelPath}/${toKebabCase(RMSModules.MOBILE_APP_CONFIGURATION)}`}
            render={() => (
              <LazyComponent>
                <RMSPage
                  subscription={RMSSubscriptions.BASE}
                  title={<Translate id="Mobile_App_Configuration" />}
                  module={RMSModules.MOBILE_APP_CONFIGURATION}
                  url={`${baseUrl}/${RMSModules.MOBILE_APP_CONFIGURATION}`}
                  propertyIdFromUrl
                  salesChannelIdFromUrl
                />
              </LazyComponent>
            )}
          />
        </>
      </Switch>
    </ErrorBoundary>
  );
};

export default SalesChannelRoutes;
