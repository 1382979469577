import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useQuery } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import EmptyComponent from '@fd/ui/EmptyComponent';
import { HORIZONTAL_SPACE_CLASSNAME } from '@fd/ui/Layout';
import PaperContainer from '@fd/ui/Layout/PaperContainer';

import { getEntitlements, getEntitlementsKey } from '../../../services/entitlements.service';
import PageLayout from '../../../ui/Layout';
import AddEntitlementOverrideModal from './AddEntitlementOverrideModal';
import EntitlementsListItem from './EntitlementsListItem';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginBottom: theme.spacing(1),
  },
  box: {
    marginBottom: theme.spacing(4),
  },
}));

const EntitlementsOverview = ({ orgId, appId }: MappedState) => {
  const classes = useStyles();

  const {
    data: entitlementsResponse,
    isPending: isLoadingEntitlements,
    refetch: refetchEntitlements,
  } = useQuery({
    queryKey: [getEntitlementsKey, orgId],
    queryFn: () => getEntitlements(orgId, ['source']),
  });

  const renderContent = () => {
    if (isLoadingEntitlements || !entitlementsResponse?.features.length) {
      return (
        <PaperContainer fluid className={classes.box}>
          <List component="nav">
            {isLoadingEntitlements && renderLoadingSkeleton()}
            {!isLoadingEntitlements && !entitlementsResponse?.features.length ? (
              <EmptyComponent title="No_entitlement_overrides_found" />
            ) : null}
          </List>
        </PaperContainer>
      );
    }

    return entitlementsResponse.features.map((feature, index: number) => {
      return (
        <>
          <Typography variant="h5" className={classes.title}>
            {feature.quantity} x {feature.code}
          </Typography>

          <PaperContainer fluid className={classes.box}>
            <List component="nav">
              {feature.source?.map((source, index: number) => {
                return (
                  <EntitlementsListItem
                    key={index}
                    appId={appId}
                    orgId={orgId}
                    isLast={index + 1 === feature.source?.length}
                    source={source}
                    refreshEntitlements={refetchEntitlements}
                    featureCode={feature.code!}
                  />
                );
              })}
            </List>
          </PaperContainer>
        </>
      );
    });
  };

  const renderLoadingSkeleton = () => {
    return (
      <ListItem
        component="div"
        disableGutters
        className={HORIZONTAL_SPACE_CLASSNAME}
        data-fd="loading-list-item"
      >
        <ListItemText primary={<Skeleton height={100} />} secondary={<Skeleton height={100} />} />
      </ListItem>
    );
  };

  return (
    <PageLayout title={<Translate id="Entitlements" />} documentTitle="Entitlements">
      {renderContent()}
      <AddEntitlementOverrideModal refreshEntitlements={refetchEntitlements} />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { currentApp } = state;
  return {
    orgId: currentApp?.OrgId ?? '',
    appId: currentApp?.AppId ?? '',
  };
};

export default connect(mapStateToProps)(EntitlementsOverview);
