import React, { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import StoreFilterInfiniteScroll from '@fd/ui/Filter/StoreFilterInfiniteScroll';

import EmptyIcon from '../../../assets/images/illust_store.svg';
import { useTracking } from '../../../services/amplitude/useTracking';
import { loadByAppId } from '../../../services/store.service';
import EmptyComponent from '../../../ui/EmptyComponent';
import PageLayout from '../../../ui/Layout';
import FiltersContainer from '../../../ui/Layout/FiltersContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import Spacer from '../../../ui/Spacer';
import { Loader } from '../../common/Loader';
import { setFilter } from './actions';
import DateFilter from './components/DateFilter';
import ReportTable from './components/ReportTable';

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) => ({
  container: {
    [breakpoints.only('xs')]: {
      borderTop: 'none',
    },
  },
  gridItem: {
    padding: spacing(1.5),
    [breakpoints.down('md')]: { padding: spacing(1) },
  },
}));

type Props = MappedState & DispatchedProps;

const EndOfDay = (props: Props) => {
  const classes = useStyles();
  const { appId, filter, setFilter } = props;
  const [hasMoreThanOneStore, setHasMoreThanOneStore] = useState(false);
  const [storeId, setStoreId] = useState<number | undefined>();
  // const tutorialStoreId = useTutorialStore(currentApp.AppId).data?.StoreId;
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_reports_endOfDay', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    if (appId) {
      loadByAppId({ appId: appId, query: '', limit: 1 })
        .then((res) => {
          if (res && res.TotalRecordCount) {
            if (res.TotalRecordCount === 1 && res.Data) {
              setStoreId(res.Data[0].StoreId);
            }
            setHasMoreThanOneStore(res.TotalRecordCount > 1);
          }
        })
        .catch(() => {
          setHasMoreThanOneStore(true);
        });
    }
  }, [appId]);

  useEffect(() => {
    if (!hasMoreThanOneStore && storeId) {
      setFilter(storeId);
    }
  }, [hasMoreThanOneStore, storeId]);

  const onSelectStore = (value: number | undefined) => {
    setFilter(value);
  };

  const header = (
    <FiltersContainer>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <DateFilter />
      </Grid>
      {hasMoreThanOneStore ? (
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <StoreFilterInfiniteScroll onSelectStore={onSelectStore} placeholder={'Select_store'} />
        </Grid>
      ) : null}
    </FiltersContainer>
  );

  return (
    <PageLayout
      fluid
      header={header}
      title={<Translate id="End_of_day_report" />}
      documentTitle="End_of_day_report"
    >
      <PaperContainer fluid className={classes.container}>
        {!hasMoreThanOneStore && !storeId ? (
          <Loader />
        ) : !filter.storeId ? (
          <EmptyComponent
            title="Select_a_store"
            subtitle="To_view_its_end_of_day_report"
            noLink
            icon={EmptyIcon}
          />
        ) : (
          <ReportTable />
        )}
        <Spacer size={16} />
      </PaperContainer>
      {/* HIDING Tutorial until work is done to update its api calls etc CLX-1508 */}
      {/* {tutorialStoreId && (
        <TutorialNotifier
          storeId={tutorialStoreId}
          onboardingItemId={604}
          status={'Completed' as OnboardingItemUpdate}
        />
      )} */}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const filter = state.reports.endOfDay.filter;

  return {
    appId: state.currentApp.AppId,
    filter,
  };
};

type DispatchedProps = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  setFilter: (storeId?: number) => {
    dispatch(setFilter({ storeId }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EndOfDay);
