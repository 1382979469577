import React, { useCallback } from 'react';

import type { Org } from '@flipdish/orgmanagement';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import PageLayout from '@fd/ui/Layout';

import { notifyError, NotifyProps, notifySaved } from '../../../../layouts/Notify/actions';
import { updateOrg, uploadImage } from '../../../RMS/organisation.services';
import { setCurrentOrg } from '../../../RMS/rms.actions';
import SettingsForm, { type FormValues } from './components/SettingsForm';

type Props = MappedState & MappedDispatch;

const Settings = ({
  currentBrandId,
  currentOrg,
  dispatchNotifySaved,
  dispatchNotifyError,
  dispatchSetCurrentOrg,
  translate,
}: Props) => {
  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      try {
        const orgId = currentOrg?.orgId ?? '';
        const { name, email, logo, countryCode } = formValues;

        let logoImageUrl = currentOrg?.logoImageUrl;
        if (logo) {
          const formDataOrg = new FormData();
          formDataOrg.append('file', logo);
          logoImageUrl = await uploadImage(orgId, formDataOrg);
        }

        const org = { orgId, name, countryCode, emailAddress: email, logoImageUrl };
        await updateOrg({
          orgId,
          updateOrg: org,
        });
        dispatchSetCurrentOrg(org);
        dispatchNotifySaved();
      } catch (e) {
        dispatchNotifyError({ message: e.message });
      }
    },
    [currentOrg?.orgId, currentOrg?.logoImageUrl]
  );

  return (
    <PageLayout
      documentTitle="Organisation_settings"
      title={translate('Organisation_settings')}
      userPermissions={'Owner'}
      toParent={`/${currentBrandId}/settings/organisation/`}
      strictToParent
    >
      <SettingsForm
        countryCode={currentOrg?.countryCode}
        email={currentOrg?.emailAddress ?? ''}
        name={currentOrg?.name ?? ''}
        onSubmit={(formValues) => handleSubmit(formValues)}
        logoImageUrl={currentOrg?.logoImageUrl ?? ''}
      />
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    currentBrandId: state.currentApp.AppId,
    currentOrg: state.rms.currentOrg,
    translate: getTranslate(state),
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
  dispatchSetCurrentOrg: (org: Org) => dispatch(setCurrentOrg(org)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
