import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';

import PageLayout from '@fd/ui/Layout';
import LazyComponent from '@fd/ui/LazyComponent';
import Tabs from '@fd/ui/Tabs';

import { lazyWithRetry } from '../../../helpers/utilities';
import { RMSModules } from '../../RMS/utils/rmsModules';
import { RMSSubscriptions } from '../../RMS/utils/rmsSubscriptions';
import Vouchers from '..';

const CircularProgressContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
}));

const RMSPage = lazyWithRetry(() => import('../../RMS/components/RMSPage'));

const VouchersPage = (props: MappedState) => {
  const { appId, translate } = props;
  const { isLoading, data } = useQueryRMSCommonDataHook(appId || '');
  const subscriptions = data?.subscriptions || [];
  const tabItems = [
    {
      label: translate('Main') as string,
      component: <Vouchers />,
      queryString: 'main',
    },
    {
      label: 'RMS',
      component: (
        <LazyComponent>
          <RMSPage
            pageLayoutEnabled={false}
            subscription={RMSSubscriptions.BASE}
            title={translate('Discount_Offers')}
            module={RMSModules.DISCOUNT_OFFERS}
            url={`modules/marketing/${RMSModules.DISCOUNT_OFFERS}`}
          />
        </LazyComponent>
      ),
      queryString: 'rms',
    },
  ];

  // edge case in which while loading RMS subscriptions the vouchers renders and overrites the url
  const oldVouchersPage = isLoading ? (
    <CircularProgressContainer>
      <CircularProgress />
    </CircularProgressContainer>
  ) : (
    <Vouchers />
  );

  return (
    <PageLayout
      auditLogsFilter={{ type: 'EventType', value: 'voucher.*' }}
      documentTitle="Vouchers"
      title={translate('Vouchers')}
    >
      {subscriptions?.includes(RMSSubscriptions.BASE) ? (
        <Tabs tabItems={tabItems} />
      ) : (
        oldVouchersPage
      )}
    </PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  return {
    appId: state?.currentApp?.AppId,
    translate: getTranslate(state.locale),
  };
};

export default connect(mapStateToProps)(VouchersPage);
