import React, { useCallback, useEffect, useState } from 'react';

import type { UpdateProperty } from '@flipdish/orgmanagement';
import FullScreenIcon from '@mui/icons-material/Fullscreen';
import FullScreenExitIcon from '@mui/icons-material/FullscreenExitOutlined';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';

import markerSvg from '../../../assets/images/marker.svg';
import { GMAP_API_KEY, libraries } from '../../../constants/map.constant';
import SearchByAddress from '../../Stores/components/SearchByAddress';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
  },
  toolbox: {
    position: 'absolute',
    right: '52px',
    bottom: '24px',
    height: '52px',
    borderRadius: '3px',
    backgroundColor: ' rgba(255, 255, 255)',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '8px',
    '& > button': {
      color: '#05149e',
    },
  },
  search: {
    position: 'absolute',
    bottom: '24px',
    left: '12px',
    backgroundColor: ' rgba(255, 255, 255)',
  },
}));

const getMapCenter = (propertyByIdData: Props['propertyByIdData']) => {
  const propertyCoords = propertyByIdData?.coordinates;
  if (propertyCoords && propertyCoords.latitude && propertyCoords.longitude) {
    return {
      lat: propertyCoords.latitude,
      lng: propertyCoords.longitude,
    };
  }
  return null;
};

export type Props = {
  propertyId?: string;
  defaultMapCenter?: { lat: number; lng: number };
  disabled?: boolean;
  propertyByIdData?: any;
  updateCoordinatesAction?: (coordinates: UpdateProperty['coordinates']) => void;
};

const PropertyLocationMap = ({
  propertyId,
  defaultMapCenter = { lat: 0, lng: 0 },
  disabled,
  propertyByIdData,
  updateCoordinatesAction,
}: Props) => {
  const classes = useStyles();

  const [fullscreen, setFullscreen] = useState(false);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [center, setCenter] = useState(getMapCenter(propertyByIdData) || defaultMapCenter);
  const [position, setPosition] = useState(getMapCenter(propertyByIdData) || defaultMapCenter);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GMAP_API_KEY ?? '',
    libraries,
  });

  useEffect(() => {
    if (propertyId && propertyByIdData) {
      const propertyCoords = getMapCenter(propertyByIdData);
      if (propertyCoords) {
        setCenter({ lat: propertyCoords.lat, lng: propertyCoords.lng });
        setPosition({ lat: propertyCoords.lat, lng: propertyCoords.lng });
      }
    } else {
      setCenter(defaultMapCenter);
      setPosition(defaultMapCenter);
    }
  }, [propertyId, propertyByIdData, defaultMapCenter]);

  useEffect(() => {
    const listener = () => {
      const parentNode = map && (map.getDiv().parentNode as HTMLDivElement);

      if (parentNode && document.fullscreenElement === parentNode) {
        setFullscreen(true);
      } else {
        setFullscreen(false);
      }
    };

    document.addEventListener('fullscreenchange', listener);

    return () => {
      document.removeEventListener('fullscreenchange', listener);
    };
  }, [map]);

  const onMapLoad = useCallback((map: google.maps.Map) => {
    setMap(map);
  }, []);

  const onMapUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onMapDragEnd = () => {
    if (map) {
      const newCenter = map.getCenter()?.toJSON();
      if (newCenter) {
        setCenter(newCenter);
      }
    }
  };

  const handleMapClick = (e: google.maps.MapMouseEvent | undefined) => {
    if (e?.latLng) {
      const newPosition = e.latLng.toJSON();
      setPosition(newPosition);
      handleSave(newPosition);
    }
  };

  const handleMarkerDragEnd = (e: google.maps.MapMouseEvent | undefined) => {
    if (e && e.latLng) {
      const newPosition = e.latLng.toJSON();
      setCenter(newPosition);
      setPosition(newPosition);
      handleSave(newPosition);
    }
  };

  const handleSave = (newPosition?: google.maps.LatLngLiteral) => {
    if (updateCoordinatesAction) {
      if (newPosition) {
        updateCoordinatesAction({
          latitude: newPosition.lat.toString(),
          longitude: newPosition.lng.toString(),
        });
      } else {
        updateCoordinatesAction({
          latitude: position.lat,
          longitude: position.lng,
        });
      }
    }
  };

  const handleSearch = (result: { lat: number; lng: number }) => {
    setCenter(result);
    setPosition(result);
    handleSave(result);
  };

  const mapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: true,
  };

  return isLoaded ? (
    <div className={classes.root} data-fd="map-root-div" style={{ height: '500px' }}>
      <GoogleMap
        mapContainerStyle={{ height: '100%' }}
        center={center}
        onDragEnd={disabled ? undefined : onMapDragEnd}
        onClick={handleMapClick}
        onLoad={onMapLoad}
        onUnmount={onMapUnmount}
        options={mapOptions}
        zoom={16}
      >
        <Marker
          draggable={!disabled}
          icon={markerSvg}
          onDragEnd={handleMarkerDragEnd}
          position={position}
        />
      </GoogleMap>

      {!disabled && (
        <div className={classes.toolbox}>
          <IconButton
            onClick={() => {
              const parentNode = map?.getDiv().parentNode as HTMLDivElement;
              if (parentNode) {
                if (document.fullscreenElement === parentNode) {
                  document.exitFullscreen();
                } else {
                  parentNode.requestFullscreen();
                }
              }
            }}
          >
            {fullscreen ? <FullScreenExitIcon /> : <FullScreenIcon />}
          </IconButton>
        </div>
      )}
      <div className={classes.search}>
        <SearchByAddress onSearch={handleSearch} />
      </div>
    </div>
  ) : null;
};

export default PropertyLocationMap;
