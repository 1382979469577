// this import provides typing for the rest of the file so it should stay here
import type SplitIO from '@splitsoftware/splitio/types/splitio';
import {
  type IInitSplitSdkParams,
  getSplitNames,
  getTreatments,
  initSplitSdk,
  mapIsFeatureOnToProps,
  selectTreatmentValue,
  selectTreatmentWithConfig,
} from '@splitsoftware/splitio-redux';

import { type FrontEndFlag } from './featureFlagList';
import { domainTag, frontEndFlags } from './featureFlagList';

const getDomainSplitList = () => frontEndFlags.map((flagName) => `${domainTag}${flagName}`);

const getSplitValue = (state, flagName: FrontEndFlag): string =>
  selectTreatmentValue(state.splitio, `${domainTag}${flagName}`);

const getSplitValueConfig = (state, flagName: FrontEndFlag) => {
  const result = selectTreatmentWithConfig(state.splitio, `${domainTag}${flagName}`);
  return {
    config: JSON.parse(result.config ?? '{}'),
    value: result.treatment,
  };
};

const isFlagOn = (state, flagName: FrontEndFlag): boolean => {
  const result = mapIsFeatureOnToProps(`${domainTag}${flagName}`)(state);
  return result.isFeatureOn;
};

const isFlagReady = (state, flagName: FrontEndFlag): boolean => {
  const result = selectTreatmentValue(state.splitio, `${domainTag}${flagName}`);
  return result === 'control' ? false : true;
};

enum TRAFFIC_TYPE {
  USER_TRAFFIC_TYPE = 'user',
  ANONYMOUS_USER_TRAFFIC_TYPE = 'anonymous',
}
const initialiseSplit = (store, splitParams) => {
  const attributes =
    splitParams.trafficType === TRAFFIC_TYPE.USER_TRAFFIC_TYPE
      ? {
          app_id: splitParams.appId as string,
          is_flipdish_user: splitParams.isFlipdishStaff as string,
          languageId: splitParams.languageId as string,
        }
      : {};

  const sdkBrowserConfig: IInitSplitSdkParams['config'] = {
    core: {
      authorizationKey: process.env.VITE_SPLIT_OAUTH_KEY ?? '',
      key: splitParams.userId,
    },
    sync: {
      splitFilters: [
        {
          type: 'byName',
          values: getDomainSplitList(),
        },
      ],
    },
  };

  const updateStore = () => {
    const splitNames = getSplitNames();
    const payload = { splitNames, attributes };
    if (splitNames?.length) {
      // @ts-ignore - grand so it is (Tim)
      store.dispatch(getTreatments(payload));
    }
  };

  store
    .dispatch(
      initSplitSdk({
        config: sdkBrowserConfig,
        onUpdate() {
          updateStore();
        },
      })
    )
    .then(() => {
      updateStore();
    });
};

export const flagService = {
  getDomainSplitList,
  getSplitValue,
  getSplitValueConfig,
  isFlagOn,
  isFlagReady,
  initialiseSplit,
  TRAFFIC_TYPE,
};
