import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import Switch from '@fd/ui/Switch/Switch';

import { notifyError, NotifyProps, notifySaved } from '../../../../../layouts/Notify/actions';
import { BasicConfiguration, RMSServices } from '../../../../RMS/rms.services';

const StyledContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: '#EEEBE9',
  borderRadius: '4px',
  marginTop: theme.spacing(9),
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

const AdminOnlyContainer = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginBottom: theme.spacing(1.5),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(2.5),
}));

const AdminOnlyText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  marginLeft: theme.spacing(1),
}));

const useStyles = makeStyles(() => ({
  label: { paddingTop: 0 },
}));

type Props = MappedState & MappedDispatch;

const AdminOnlyOptions = ({ appId, dispatchNotifySaved, dispatchNotifyError }: Props) => {
  const classes = useStyles();

  const { isLoading, data } = useQueryRMSCommonDataHook(appId);

  const orgHierarchy = data?.associations?.orgHierarchy;
  const franchisorId = orgHierarchy?.franchisor?.id ?? '';

  const queryClient = useQueryClient();

  const { data: configData, isPending } = useQuery({
    queryFn: () => RMSServices.getOrganisationBasicConfig(franchisorId),
    queryKey: [RMSServices.getOrganisationBasicConfigKey, franchisorId],
    enabled: !!franchisorId,
  });

  const { mutateAsync: updateConfig } = useMutation({
    mutationFn: (config: BasicConfiguration) =>
      RMSServices.updateOrganisationBasicConfig(franchisorId, config),
    onSuccess() {
      dispatchNotifySaved();
    },
    onMutate: async (newData) => {
      await queryClient.cancelQueries({
        queryKey: [RMSServices.getOrganisationBasicConfigKey, franchisorId],
      });
      const previousData = queryClient.getQueryData<BasicConfiguration>([
        RMSServices.getOrganisationBasicConfigKey,
        franchisorId,
      ]);
      queryClient.setQueryData([RMSServices.getOrganisationBasicConfigKey, franchisorId], () => {
        return { data: newData };
      });
      return { previousData };
    },
    onError(error) {
      dispatchNotifyError({ message: error.message });
    },
  });

  const handleSubmit = async ({
    key,
    value,
  }: {
    key: keyof BasicConfiguration;
    value: BasicConfiguration[keyof BasicConfiguration];
  }) => {
    if (configData) {
      await updateConfig({ ...configData.data, [key]: value });
    }
  };

  return (
    <StyledContainer>
      <AdminOnlyContainer>
        <SupportAgentOutlinedIcon />
        <AdminOnlyText>
          <Translate id="Admin_Only" />
        </AdminOnlyText>
      </AdminOnlyContainer>
      {!isPending && (
        <>
          <Grid item xs={12}>
            <FormItemLayout
              label={<Translate id={'Enable_organisation'} />}
              removeRootPaddings
              alignItems="center"
              labelClass={classes.label}
              paddingBottom="20px"
            >
              <Switch
                edge="end"
                value={configData?.data.isBlocked}
                checked={configData?.data.isEnabled}
                onChange={(e) => handleSubmit({ key: 'isEnabled', value: e.target.checked })}
                disabled={isLoading}
                inputProps={{
                  'aria-labelledby': 'org-isEnabled-toggle',
                }}
                fdKey="org-isEnabled-toggle"
              />
            </FormItemLayout>
          </Grid>

          <Grid item xs={12}>
            <FormItemLayout
              label={<Translate id={'Display_Flipdish_branding'} />}
              removeRootPaddings
              alignItems="center"
              labelClass={classes.label}
            >
              <Switch
                edge="end"
                value={configData?.data.isBlocked}
                checked={configData?.data.displayResellerBranding}
                onChange={(e) =>
                  handleSubmit({ key: 'displayResellerBranding', value: e.target.checked })
                }
                disabled={isLoading}
                inputProps={{
                  'aria-labelledby': 'flipdish-branding-toggle',
                }}
                fdKey="flipdish-branding-toggle"
              />
            </FormItemLayout>
          </Grid>
        </>
      )}
    </StyledContainer>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => ({
  appId: state.currentApp.AppId ?? '',
});

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminOnlyOptions);
