import React from 'react';

import { Translate } from 'react-localize-redux';
import { type RouteComponentProps, Route } from 'react-router-dom';

import LazyComponent from '@fd/ui/LazyComponent';

import { lazyWithRetry } from '../../../helpers/utilities';
import ErrorBoundary from '../../../layouts/Portal/ErrorBoundary';
import { RMSModules } from '../utils/rmsModules';
import { RMSSubscriptions } from '../utils/rmsSubscriptions';
import CentralKitchenManagement from './CentralKitchenManagement';

const CentralKitchen = lazyWithRetry(() => import('./CentralKitchen'));
const RMSPage = lazyWithRetry(() => import('../components/RMSPage'));

const CentralKitchenRoutes = (props: RouteComponentProps) => {
  const {
    match: { path },
  } = props;
  const configBaseUrl = `configurations/franchisors/${RMSModules.CENTRAL_KITCHEN}`;
  const inventoryBaseUrl = `modules/inventory`;

  return (
    <ErrorBoundary identifier="CentralKitchen">
      <Route
        exact
        path={path}
        render={() => (
          <LazyComponent>
            <CentralKitchen />
          </LazyComponent>
        )}
      />
      <Route
        exact
        path={`${path}/order-management`}
        render={() => (
          <LazyComponent>
            <RMSPage
              includePropertyIdInUrl={true}
              module={RMSModules.CENTRAL_KITCHEN}
              url={`${configBaseUrl}/${RMSModules.SALES}`}
              subscription={RMSSubscriptions.CENTRAL_KITCHEN}
              title={<Translate id="Central_Kitchen_Sales_and_Order_Management" />}
            />
          </LazyComponent>
        )}
      />
      <Route
        exact
        path={`${path}/configuration`}
        render={() => (
          <LazyComponent>
            <CentralKitchenManagement />
          </LazyComponent>
        )}
      />
      <Route
        exact
        path={`${path}/configuration/setup`}
        render={() => (
          <LazyComponent>
            <RMSPage
              includePropertyIdInUrl={true}
              module={RMSModules.CENTRAL_KITCHEN}
              url={`${configBaseUrl}/${RMSModules.SETUP}`}
              subscription={RMSSubscriptions.CENTRAL_KITCHEN}
              title={<Translate id="Basic_Configuration" />}
            />
          </LazyComponent>
        )}
      />
      <Route
        exact
        path={`${path}/configuration/menus`}
        render={() => (
          <LazyComponent>
            <RMSPage
              includePropertyIdInUrl={true}
              module={RMSModules.CENTRAL_KITCHEN}
              url={`modules/${RMSModules.MENUS}`}
              subscription={RMSSubscriptions.CENTRAL_KITCHEN}
              title={<Translate id="Menus" />}
            />
          </LazyComponent>
        )}
      />
      <Route
        exact
        path={`${path}/configuration/stock-item-categories`}
        render={() => (
          <LazyComponent>
            <RMSPage
              includePropertyIdInUrl={true}
              module={RMSModules.CENTRAL_KITCHEN}
              url={`${inventoryBaseUrl}/${RMSModules.STOCK_ITEM_CATEGORIES}`}
              subscription={RMSSubscriptions.CENTRAL_KITCHEN}
              title={<Translate id="Stock_Item_Categories" />}
            />
          </LazyComponent>
        )}
      />
      <Route
        exact
        path={`${path}/configuration/stock-items`}
        render={() => (
          <LazyComponent>
            <RMSPage
              includePropertyIdInUrl={true}
              module={RMSModules.CENTRAL_KITCHEN}
              url={`${inventoryBaseUrl}/${RMSModules.STOCK_ITEMS}`}
              subscription={RMSSubscriptions.CENTRAL_KITCHEN}
              title={<Translate id="Inventory_Stock_Items" />}
            />
          </LazyComponent>
        )}
      />
      <Route
        exact
        path={`${path}/configuration/suppliers`}
        render={() => (
          <LazyComponent>
            <RMSPage
              includePropertyIdInUrl={true}
              module={RMSModules.CENTRAL_KITCHEN}
              url={`${inventoryBaseUrl}/${RMSModules.SUPPLIERS}`}
              subscription={RMSSubscriptions.CENTRAL_KITCHEN}
              title={<Translate id="Suppliers" />}
            />
          </LazyComponent>
        )}
      />
      <Route
        exact
        path={`${path}/configuration/inventory-tracking-profiles`}
        render={() => (
          <LazyComponent>
            <RMSPage
              includePropertyIdInUrl={true}
              module={RMSModules.CENTRAL_KITCHEN}
              url={`${inventoryBaseUrl}/${RMSModules.TRACKING_PROFILES}`}
              subscription={RMSSubscriptions.CENTRAL_KITCHEN}
              title={<Translate id="Inventory_Tracking_Profiles" />}
            />
          </LazyComponent>
        )}
      />
    </ErrorBoundary>
  );
};

export default CentralKitchenRoutes;
