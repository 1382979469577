import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import { useQueryRMSSalesChannels } from '@fd/customHooks/useQueryRMSSalesChannels';
import { useParams } from 'react-router-dom';

import { useAppSelector } from './useAppSelector';

const useQuerySalesChannelsByParams = () => {
  const org = useAppSelector((state) => state.rms.currentOrg);
  const appId = useAppSelector((state) => state.currentApp.AppId);

  const params = useParams<{ channelId: string; propertyId: string }>();
  const channelId = params.channelId;
  const propertyId = params.propertyId;
  const isOrgApiPropertyId = !!propertyId?.startsWith('p');

  const { data: commonData } = useQueryRMSCommonDataHook(appId || '');

  const orgPropertyId =
    (isOrgApiPropertyId
      ? propertyId
      : commonData?.associations?.orgHierarchy?.properties?.find(
          (property) => property.code === propertyId || property.id === propertyId
        )?.code) || propertyId;

  const { data } = useQueryRMSSalesChannels({
    orgId: org?.orgId ?? '',
    propertyId: orgPropertyId ?? '',
  });

  return data?.find((salesChannel) => salesChannel?.salesChannelId === channelId) || null;
};

export default useQuerySalesChannelsByParams;
