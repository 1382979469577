import React, { useMemo, useState } from 'react';

import { type DynamicFormField } from '@flipdish/api-client-typescript';
import type {
  CreateProperty,
  Property,
  PropertyCountryCodeEnum,
  UpdateProperty,
} from '@flipdish/orgmanagement';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import type { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import { type FormikProps, Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import PageLayout from '@fd/ui/Layout';

import {
  closeNotifySaving,
  notifyError,
  NotifyProps,
  notifySaved,
  notifySaving,
} from '../../../layouts/Notify/actions';
import { appMapCenterSelector } from '../../../selectors/app.selector';
import GridContainer from '../../../ui/Layout/GridContainer';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import { FormikInputField } from '../../../ui/molecules/FormikInputField';
import PreventNavigation from '../../Finance/Banking/components/PreventNavigation';
import { getAssociationsKey } from '../../RMS/rms.services';
import { dublinCoordinates } from '../constants';
import { createPropertyForOrg, updatePropertyById } from '../properties.services';
import { validatePropertyForm } from '../utils';
import PropertyAddressForm from './PropertyAddressForm';
import PropertyLocationMap from './PropertyLocationMap';

const maxWidth = '718px';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: maxWidth,
    [theme.breakpoints.down('md')]: {
      margin: '0px',
      width: '100%',
      maxWidth: '100%',
      boxShadow: 'none',
    },
  },
  item: {
    padding: '16px 0px',
    minHeight: '24px',
  },
  mapItem: {
    minHeight: '240px',
  },
  textField: {
    height: '40px',
    margin: '5px',
    padding: '0px 16px 0px 0px',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  grey: {
    color: 'rgba(0, 0, 0, 0.6)',
  },
  alignSelfCenter: {
    alignSelf: 'center',
  },
  gridItem: {
    padding: `${theme.spacing(1.5)}!important`,
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

type OuterProps = {
  propertyId: string;
  propertyData: Property;
};

type Props = MappedState & MappedDispatch & OuterProps;

const CreateAndEditProperty = (props: Props) => {
  const {
    accountEmail,
    currentOrg,
    dispatchCloseNotifySaving,
    dispatchNotifyError,
    dispatchNotifySaved,
    dispatchNotifySaving,
    selectedApp,
    translate,
    defaultMapCenter,
    propertyId,
    propertyData,
  } = props;
  const [addressFormAndValues, setAddressFormAndValues] = useState<{
    values: Property;
    addressFormData: Array<DynamicFormField>;
  }>({ values: {}, addressFormData: [] });
  const queryClient = useQueryClient();
  const orgId = currentOrg?.orgId || '';

  const isEditMode = Boolean(propertyId);
  const classes = useStyles();
  const history = useHistory();

  const dynamicAddressFieldsWithInitialValues = useMemo(() => {
    const initialValues = {};
    (addressFormAndValues?.addressFormData || []).forEach((dynamicField) => {
      const identifier = dynamicField?.Identifier;
      if (identifier) {
        initialValues[identifier] =
          addressFormAndValues?.values?.addressFields?.[identifier] ||
          propertyData?.addressFields?.[identifier] ||
          '';
      }
    });
    return initialValues;
  }, [addressFormAndValues, propertyData]);

  const initialValues = {
    name: addressFormAndValues?.values?.name || propertyData?.name || '',
    countryCode:
      addressFormAndValues?.values?.countryCode ||
      propertyData?.countryCode ||
      ('' as PropertyCountryCodeEnum),
    emailAddress:
      addressFormAndValues?.values?.emailAddress ||
      propertyData?.emailAddress ||
      accountEmail ||
      '',
    phoneNumber: addressFormAndValues?.values?.phoneNumber || propertyData?.phoneNumber || '',
    addressFields: dynamicAddressFieldsWithInitialValues || {},
    coordinates: addressFormAndValues?.values?.coordinates ||
      propertyData?.coordinates || {
        latitude: defaultMapCenter.lat,
        longitude: defaultMapCenter.lng,
      },
    logoImageUrl: addressFormAndValues?.values?.logoImageUrl || propertyData?.logoImageUrl || '',
  };

  const createPropertyMutation = useMutation({
    mutationFn: (newProperty: CreateProperty) => createPropertyForOrg(orgId, newProperty),
    onSuccess: () => {
      dispatchCloseNotifySaving();
      dispatchNotifySaved();
      queryClient.invalidateQueries({ queryKey: [getAssociationsKey] });
      history.push(`/${selectedApp.AppId}/properties`);
    },
    onError: () => {
      dispatchCloseNotifySaving();
      dispatchNotifyError({ message: 'Failed_to_create_property', translate: true });
    },
  });

  const updatePropertyMutation = useMutation({
    mutationFn: (updatedProperty: UpdateProperty) =>
      updatePropertyById(orgId, propertyId, updatedProperty),
    onSuccess: () => {
      dispatchCloseNotifySaving();
      dispatchNotifySaved();
      queryClient.invalidateQueries({ queryKey: [getAssociationsKey] });
      queryClient.invalidateQueries({ queryKey: ['getPropertyById'] });
    },
    onError: () => {
      dispatchCloseNotifySaving();
      dispatchNotifyError({ message: 'Failed_to_update_property', translate: true });
    },
  });

  const handleSubmit = async (values, { setTouched }) => {
    try {
      dispatchNotifySaving();
      if (isEditMode) {
        await updatePropertyMutation.mutateAsync(values);
      } else {
        await createPropertyMutation.mutateAsync(values);
      }
      setTouched({});
    } catch (error) {
      dispatchNotifyError({ message: 'Error_saving_property', translate: true });
    }
  };

  const content = (
    <PaperContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validate={(values) => {
          const { coordinates } = values;
          if (
            coordinates &&
            coordinates.latitude === dublinCoordinates.latitude &&
            coordinates.longitude === dublinCoordinates.longitude
          ) {
            dispatchNotifyError({
              message: 'Make_sure_to_set_your_map_pin_so_customers_can',
              translate: true,
            });
          }
          return validatePropertyForm(values, translate);
        }}
        onSubmit={handleSubmit}
      >
        {({ dirty, isSubmitting, setFieldValue, setFieldTouched, touched }: FormikProps<any>) => {
          return (
            <Form>
              <PreventNavigation when={dirty && !isSubmitting && !isEmpty(touched)} />
              <GridContainer
                direction="row"
                justifyContent="space-between"
                className={classes.item}
              >
                <Grid item xs={12} sm={5} className={classes.gridItem}>
                  <InputLabel htmlFor="name" style={{ transform: 'initial' }}>
                    {translate('Property_name')}
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.gridItem}>
                  <FormikInputField
                    variant="standard"
                    fdKey="name"
                    placeholder={translate('Enter_property_name') as string}
                    label={translate('Enter_property_name') as string}
                    name="name"
                  />
                </Grid>
              </GridContainer>
              <Divider />
              <PropertyAddressForm
                setAddressFormAndValues={setAddressFormAndValues}
                countryCodeInitialState={initialValues.countryCode}
              />
              <Divider />
              <GridContainer
                direction="row"
                justifyContent="space-between"
                className={classes.item}
              >
                <Grid item xs={5} className={classes.gridItem}>
                  <Typography variant={'body1'} className={classes.grey}>
                    <Translate id="Map_location" />
                  </Typography>
                </Grid>
                <Grid item xs={7} className={clsx(classes.alignSelfCenter, classes.gridItem)}>
                  <Typography variant={'caption'}>
                    <Translate id="Choose_the_exact_location_your_store_is_on_a_map" />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={clsx(classes.mapItem, classes.gridItem)}>
                  <PropertyLocationMap
                    propertyId={propertyId}
                    propertyByIdData={propertyData}
                    defaultMapCenter={defaultMapCenter}
                    updateCoordinatesAction={async (coordinates) => {
                      await setFieldTouched('coordinates.latitude', true);
                      await setFieldValue('coordinates', coordinates);
                    }}
                  />
                </Grid>
              </GridContainer>
              <Divider />
              <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.item}
              >
                <Grid item xs={12} sm={5} className={classes.gridItem}>
                  <InputLabel htmlFor="phoneNumber" style={{ transform: 'initial' }}>
                    <Translate id="Property_phone" />
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.gridItem}>
                  <FormikInputField
                    variant="standard"
                    fdKey="phoneNumber"
                    placeholder="+123456789"
                    name="phoneNumber"
                  />
                </Grid>
              </GridContainer>
              <Divider />
              <GridContainer
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={classes.item}
              >
                <Grid item xs={12} sm={5} className={classes.gridItem}>
                  <InputLabel>
                    <Translate id="Property_timezone" />
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.gridItem}>
                  <Typography
                    className={classes.textField}
                    style={{ lineHeight: '40px', marginLeft: 0 }}
                  >
                    {propertyData?.timeZone}
                  </Typography>
                </Grid>
              </GridContainer>
              <Divider />
              <GridContainer
                direction="row"
                justifyContent="space-between"
                className={classes.item}
              >
                <Grid item xs={12} sm={5} className={classes.gridItem}>
                  <InputLabel htmlFor="emailAddress" style={{ transform: 'initial' }}>
                    <Translate id="Property_email" />
                  </InputLabel>
                </Grid>
                <Grid item xs={12} sm={7} className={classes.gridItem}>
                  <FormikInputField
                    variant="standard"
                    fdKey="emailAddress"
                    placeholder={
                      initialValues.emailAddress
                        ? initialValues.emailAddress
                        : (translate('Add_email') as string)
                    }
                    label={translate('Add_email') as string}
                    name="emailAddress"
                  />
                </Grid>
              </GridContainer>
              <Divider />
              <Grid container className={classes.gridItem} justifyContent="flex-end">
                <Button type="submit" variant="contained" color="primary">
                  {isEditMode ? translate('Save') : translate('Add_Property')}
                </Button>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </PaperContainer>
  );

  return isEditMode ? (
    content
  ) : (
    <PageLayout title={translate('Add_new_property')}>{content}</PageLayout>
  );
};

type MappedState = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const defaultMapCenter = appMapCenterSelector(state);
  return {
    defaultMapCenter,
    selectedApp: state.currentApp,
    currentOrg: state.rms.currentOrg,
    translate: getTranslate(state.locale),
    accountEmail: state.account.Email,
  };
};

type MappedDispatch = ReturnType<typeof mapDispatchToProps>;
const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  dispatchCloseNotifySaving: () => dispatch(closeNotifySaving()),
  dispatchNotifyError: (data: NotifyProps) => dispatch(notifyError(data)),
  dispatchNotifySaved: () => dispatch(notifySaved()),
  dispatchNotifySaving: () => dispatch(notifySaving({ persist: true })),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateAndEditProperty);
