import React from 'react';

import * as FlipdishAPI from '@flipdish/api-client-typescript';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeliveryIcon from '@mui/icons-material/DirectionsBike';
import MenusIcon from '@mui/icons-material/FastfoodOutlined';
import Checkbox, { type CheckboxProps } from '@mui/material/Checkbox';
import Fab from '@mui/material/Fab';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import createStyles from '@mui/styles/createStyles';
import withStyles, { type WithStyles } from '@mui/styles/withStyles';
import clsx from 'clsx';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import Permissions from 'react-redux-permissions';
import { Link } from 'react-router-dom';
import { compose } from 'redux';

import EmptyComponent from '@fd/ui/EmptyComponent';
import { FabButtonLink } from '@fd/ui/FabButtonLink';
import GridContainer from '@fd/ui/Layout/GridContainer';
import PaperContainer from '@fd/ui/Layout/PaperContainer';
import WindowInfiniteScroll from '@fd/ui/WindowInfiniteScroll';

import { vouchersActions } from '../../actions/vouchers.actions';
import CreditNote from '../../assets/images/CreditNote.svg';
import PercentageDiscount from '../../assets/images/PercentageDiscount.svg';
import { generalConstants } from '../../constants/general.constants';
import { HORIZONTAL_SPACE_CLASSNAME } from '../../ui/Layout';
import { AppLoadingCircularProgress } from '../AppLoadingCircularProgress';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../WithRouteSearchParams';
import { VoucherSelect } from './components/VoucherSelect';

const AppResourceSetEnum = FlipdishAPI.App.AppResourceSetEnum;

const FormControlLabelSmall = withStyles((theme: Theme) => ({
  label: {
    ...theme.typography.caption,
  },
}))(FormControlLabel);
const CheckboxSmall = ({ label, ...props }: CheckboxProps & { label: React.ReactNode }) => (
  <FormControlLabelSmall
    control={
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon fontSize="small" />}
        {...props}
      />
    }
    label={label}
  />
);

const styles = (theme: Theme) =>
  createStyles({
    paperGrid: {
      marginTop: '32px',
    },
    table: {
      width: '100%',
    },
    cell: {
      padding: '16px 24px',
      borderBottomColor: 'rgba(0,0,0,0.21)',
    },
    headCell: {
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '2',
    },
    bodyCell: {
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.85',
      letterSpacing: 'normal',
      padding: 0,
    },
    cellLink: {
      display: 'block',
      padding: '16px 24px',
      textDecoration: 'none',
      color: 'inherit',
      outline: 0,
    },
    iconColour: {
      color: theme.palette.common.white,
    },
    responsiveCellLink: {
      display: 'block',
      textDecoration: 'none',
      color: 'inherit',
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#eaf2ff',
      },
    },
    statusValid: {
      color: 'rgba(37, 201, 116, 0.87)!important',
    },
    statusUsed: {
      color: '#4f94f7!important',
    },
    statusExpired: {
      color: 'rgba(255, 57, 95, 0.87)!important',
    },
    statusDisabled: {
      color: 'rgba(0, 0, 0, 0.4)!important',
    },
    type: {
      width: '40px',
      height: '40px',
      borderRadius: '100%',
      margin: '0 auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        margin: 0,
      },
    },
    typeValid: {
      backgroundColor: '#76e3b6',
    },
    typeUsed: {
      backgroundColor: '#95bffa',
    },
    typeExpired: {
      backgroundColor: '#fb7c92',
    },
    typeDisabled: {
      backgroundColor: '#dbdbdb',
    },
    typeNotYetValid: {
      backgroundColor: '#05149e',
    },
    typeCell: {
      width: `${100 / 15}%`,
    },
    codeCell: {
      width: `${(4 * 100) / 15}%`,
    },
    detailsCell: {
      width: `${100 / 3}%`,
    },
    responsiveTable: {
      paddingTop: '8px',
      marginTop: '24px',
    },
    responsiveCell: {
      padding: '12px 16px',
      display: 'flex',
    },
    responsiveCellContents: {
      paddingLeft: '16px',
      paddingRight: '8px',
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: '0%',
    },
    responsiveCode: {
      fontSize: '16px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.5,
      letterSpacing: '0.2px',
      color: 'rgba(0, 0, 0, 0.87)',
    },
    responsiveDescription: {
      fontSize: '12px',
      fontWeight: 'normal',
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 1.33,
      letterSpacing: '0.4px',
      color: 'rgba(0, 0, 0, 0.6)',
    },

    hyperlink: {
      color: '#05149e',
      textDecoration: 'none',
    },

    button: {
      margin: theme.spacing(1),
      color: 'white',
      position: 'fixed',
      bottom: '24px',
      right: '24px',
      backgroundColor: '#ff0046',
      fontSize: '14px',
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1.14',
      letterSpacing: '1.3px',
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    gridItem: {
      padding: theme.spacing(1.5),
      [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
    },
    headerContainer: {
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  });

type Props = WithStyles<typeof styles> &
  MappedProps &
  WithRouteSearchParamsProps<{ [key: string]: string | string[] }> & { dispatch: ThunkDispatch };
class Vouchers extends React.Component<Props, any> {
  public state = {
    showGeneratedVouchers:
      this.props.search && this.props.search.custom ? this.props.search.custom === 'false' : false,
  };

  public UNSAFE_componentWillMount() {
    const { dispatch, appId, search } = this.props;

    const AppId = appId || 'global';
    dispatch(vouchersActions.setVouchersAppId(AppId));
    const searchForCustom = search && search.custom ? search.custom === 'true' : true;

    dispatch(vouchersActions.setVoucherSearchCustom(searchForCustom));

    const searchParams = Object.keys(search || {}).reduce<any>((agg, key) => {
      const value: string | string[] | undefined = search![key];
      if (key !== 'custom' && value) {
        if (Array.isArray(value)) {
          value.forEach((v: string) => {
            agg.push({
              label: `${key}:${v.toLowerCase()}`,
              type: `${key}:${v.toLowerCase()}`,
              value: v,
            });
          });
        } else {
          agg.push({
            label: `${key}:${value.toLowerCase()}`,
            type: `${key}:${value.toLowerCase()}`,
            value: search![key],
          });
        }
      }
      return agg;
    }, []);
    // 13. URL bar now shows filter code with number
    dispatch(vouchersActions.setVoucherSearchParams(searchParams));

    const searchCodes =
      search && search.code
        ? Array.isArray(search.code)
          ? search.code
          : [search.code]
        : undefined;

    const statusSearch =
      search && search.status
        ? Array.isArray(search.status)
          ? (search.status as any)
          : ([search.status] as any)
        : undefined;

    const typeSearch =
      search && search.type
        ? Array.isArray(search.type)
          ? (search.type as any)
          : ([search.type] as any)
        : undefined;

    const channelRestrictions =
      search && search.channel
        ? Array.isArray(search.channel)
          ? (search.channel as any)
          : ([search.channel] as any)
        : undefined;

    dispatch(
      vouchersActions.getVouchers(
        AppId,
        1,
        generalConstants.VOUCHERS_PAGE_SIZE,
        false,
        searchCodes,
        statusSearch,
        typeSearch,
        searchForCustom ? ['Custom'] : undefined,
        undefined,
        channelRestrictions
      )
    );
  }

  public handleCustomVouchersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    this.setState(
      {
        showGeneratedVouchers: checked,
      },
      () => {
        this.searchVouchers();
      }
    );
  };

  public getFurtherVouchers = async (pageToLoad = 1) => {
    const { dispatch, appId } = this.props;
    const { searchCodes, statusSearch, typeSearch, subTypeSearch } = this.createVouchersSearch(
      !this.state.showGeneratedVouchers
    );
    await dispatch(
      vouchersActions.getVouchers(
        appId,
        pageToLoad,
        generalConstants.VOUCHERS_PAGE_SIZE,
        true,
        searchCodes,
        statusSearch,
        typeSearch,
        subTypeSearch
      )
    );
  };

  public render() {
    const {
      classes,
      translate,
      vouchers,
      vouchersCount,
      vouchersTimestamp,
      vouchersLoading,
      appId,
    } = this.props;

    const isEmpty = vouchers.length === 0 && !vouchersTimestamp;

    const typeIcon = (type) => {
      switch (type) {
        case 'PercentageDiscount':
          return <img src={PercentageDiscount} />;
        case 'LumpDiscount':
          return <AttachMoneyIcon className={classes.iconColour} />;
        case 'AddItem':
          return <MenusIcon className={classes.iconColour} data-fd="menu-icon" />;
        case 'FreeDelivery':
          return <DeliveryIcon className={classes.iconColour} data-fd="freeDelivery-icon" />;
        case 'CreditNote':
          return <img src={CreditNote} />;
        default:
          return null;
      }
    };
    const header = (
      <GridContainer className={classes.headerContainer}>
        <Grid item xs={12} md={6} className={classes.gridItem}>
          <VoucherSelect search={this.props.search} setSearch={this.props.setSearch} />
        </Grid>
        <Grid item xs={12} md={6} container alignContent="center" className={classes.gridItem}>
          <CheckboxSmall
            checked={this.state.showGeneratedVouchers}
            onChange={this.handleCustomVouchersChange}
            value="customVouchersSwitch"
            data-fd="switch_all_custom_vouchers"
            label={<Translate id="Show_generated_vouchers" />}
            color="secondary"
          />
        </Grid>
      </GridContainer>
    );

    return (
      <WindowInfiniteScroll
        pageStart={1}
        loadMore={this.loadMore}
        hasMore={vouchers.length < vouchersCount}
        lock={vouchersLoading}
        initialLoad={false}
      >
        {header}
        <PaperContainer fluid>
          {isEmpty ? (
            <EmptyComponent title="No_vouchers" subtitle="Vouchers_contact_flipdish" noLink />
          ) : (
            <>
              <Hidden mdDown>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={clsx(classes.headCell, classes.cell, classes.typeCell)}>
                        {translate('Type')}
                      </TableCell>
                      <TableCell className={clsx(classes.headCell, classes.cell, classes.codeCell)}>
                        {translate('Code')}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.headCell, classes.cell, classes.detailsCell)}
                      >
                        {translate('Details')}
                      </TableCell>
                      <TableCell
                        className={clsx(classes.headCell, classes.cell, classes.detailsCell)}
                      >
                        {translate('Status')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody data-fd="vouchers-table">
                    {vouchers.map((voucher, index) => (
                      <TableRow className={classes.row} key={index} data-fd={voucher.Code}>
                        <TableCell
                          className={clsx(classes.bodyCell, classes.cell, classes.typeCell)}
                        >
                          <Link
                            to={'/' + appId + '/vouchers/' + voucher.VoucherId}
                            className={classes.cellLink}
                          >
                            <div
                              className={clsx(
                                classes.type,
                                voucher.IsEnabled
                                  ? classes[`type${voucher.Status}`]
                                  : classes.typeDisabled
                              )}
                            >
                              {typeIcon(voucher.VoucherType)}
                            </div>
                          </Link>
                        </TableCell>
                        <TableCell
                          className={clsx(classes.bodyCell, classes.cell, classes.codeCell)}
                        >
                          <Link
                            to={'/' + appId + '/vouchers/' + voucher.VoucherId}
                            className={classes.cellLink}
                          >
                            {voucher.Code}
                          </Link>
                        </TableCell>
                        <TableCell
                          className={clsx(classes.bodyCell, classes.cell, classes.detailsCell)}
                        >
                          <Link
                            to={'/' + appId + '/vouchers/' + voucher.VoucherId}
                            className={classes.cellLink}
                          >
                            {voucher.Description}
                          </Link>
                        </TableCell>
                        <TableCell
                          className={clsx(
                            classes.bodyCell,
                            classes.cell,
                            classes.detailsCell,
                            voucher.IsEnabled || voucher.Status != 'Valid'
                              ? classes[`status${voucher.Status}`]
                              : classes.statusDisabled
                          )}
                        >
                          <Link
                            to={'/' + appId + '/vouchers/' + voucher.VoucherId}
                            className={classes.cellLink}
                          >
                            {voucher.IsEnabled || voucher.Status != 'Valid'
                              ? translate(voucher.Status)
                              : translate('Disabled')}
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                    {vouchersTimestamp ? (
                      <TableRow>
                        <TableCell colSpan={4} className={clsx(classes.bodyCell, classes.cell)}>
                          <AppLoadingCircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : null}
                  </TableBody>
                </Table>
              </Hidden>
              <Hidden mdUp>
                <div className={classes.responsiveTable}>
                  {vouchers.map((voucher, key: number) => (
                    <Link
                      to={'/' + appId + '/vouchers/' + voucher.VoucherId}
                      className={classes.responsiveCellLink}
                      key={key}
                      data-fd={voucher.Code}
                    >
                      <div
                        className={clsx(
                          classes.responsiveCell,
                          classes.row,
                          HORIZONTAL_SPACE_CLASSNAME
                        )}
                      >
                        <div
                          className={clsx(
                            classes.type,
                            voucher.IsEnabled
                              ? classes[`type${voucher.Status}`]
                              : classes.typeDisabled
                          )}
                        >
                          {typeIcon(voucher.VoucherType)}
                        </div>
                        <div className={classes.responsiveCellContents}>
                          <Typography
                            variant="h4"
                            component="h4"
                            className={classes.responsiveCode}
                          >
                            {voucher.Code}
                          </Typography>
                          <Typography
                            variant="h6"
                            component="h6"
                            className={classes.responsiveDescription}
                          >
                            {voucher.Description}
                          </Typography>
                        </div>
                        <Typography
                          variant="h6"
                          component="h6"
                          className={clsx(
                            classes.responsiveDescription,
                            voucher.IsEnabled || voucher.Status != 'Valid'
                              ? classes[`status${voucher.Status}`]
                              : classes.statusDisabled
                          )}
                        >
                          {voucher.IsEnabled || voucher.Status != 'Valid'
                            ? translate(voucher.Status)
                            : translate('Disabled')}
                        </Typography>
                      </div>
                    </Link>
                  ))}
                  {vouchersTimestamp ? (
                    <div className={classes.responsiveCell}>
                      <AppLoadingCircularProgress />
                    </div>
                  ) : null}
                </div>
              </Hidden>
            </>
          )}
        </PaperContainer>

        <Permissions allowed={[AppResourceSetEnum.EditVouchers]}>
          <Fab
            variant="extended"
            color="secondary"
            className={classes.button}
            to={'/' + appId + '/vouchers/new'}
            aria-label={translate('Add_new') as string}
            data-fd="voucher-add"
            component={FabButtonLink}
          >
            <AddIcon className={classes.extendedIcon} />
            {translate('Add_new')}
          </Fab>
        </Permissions>
      </WindowInfiniteScroll>
    );
  }

  private loadMore = async (page) => {
    try {
      await this.getFurtherVouchers(page);
    } catch (error) {
      console.error(error);
    }
  };

  private setUrlParams = (searchForCustom: boolean) => {
    const { dispatch, setSearch, search } = this.props;
    setSearch({ ...search, custom: searchForCustom ? 'true' : 'false' });
    dispatch(vouchersActions.setVoucherSearchCustom(searchForCustom));
  };

  private createVouchersSearch = (custom?: boolean) => {
    const { voucherSearchParams } = this.props;

    const searchCodes: any[] = [];
    const statusSearch: any[] = [];
    const typeSearch: any[] = [];
    const subTypeSearch: any[] = [];

    for (const param of voucherSearchParams) {
      if (param.type.includes('status:')) {
        statusSearch.push(param.value);
      } else if (param.type.includes('code:')) {
        searchCodes.push(param.value);
      } else if (param.type.includes('type:')) {
        typeSearch.push(param.value);
      }
    }

    if (custom) {
      subTypeSearch.push('Custom');
    }

    return { searchCodes, statusSearch, typeSearch, subTypeSearch };
  };

  private searchVouchers = () => {
    const { dispatch, appId } = this.props;
    const searchForCustom = !this.state.showGeneratedVouchers;
    this.setUrlParams(searchForCustom);
    const { searchCodes, statusSearch, typeSearch, subTypeSearch } =
      this.createVouchersSearch(searchForCustom);
    dispatch(
      vouchersActions.getVouchers(
        appId,
        1,
        generalConstants.VOUCHERS_PAGE_SIZE,
        false,
        searchCodes,
        statusSearch,
        typeSearch,
        subTypeSearch
      )
    );
  };
}

type MappedProps = ReturnType<typeof mapStateToProps>;
function mapStateToProps(state: AppState) {
  const { locale, currentApp, vouchers } = state;
  return {
    appId: currentApp.AppId,
    translate: getTranslate(locale),
    vouchers: vouchers.vouchers,
    vouchersLoading: vouchers.vouchersLoading,
    vouchersCount: vouchers.vouchersCount,
    vouchersTimestamp: vouchers.vouchersTimestamp,
    voucherSearchParams: vouchers.voucherSearchParams,
    voucherSearchCustom: vouchers.voucherSearchCustom,
    vouchersAppId: vouchers.vouchersAppId,
  };
}

export default compose(
  withStyles(styles, {
    name: 'Vouchers',
    withTheme: true,
  }),
  withRouteSearchParams({
    name: 'filter',
  }),
  connect(mapStateToProps)
)(Vouchers);
