import React from 'react';

import { useAppSelector } from '@fd/customHooks/useAppSelector';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import { getTranslate } from 'react-localize-redux';

import Tooltip from '@fd/ui/Tooltip/Tooltip';

const StyledListItemText = styled(ListItemText)(() => ({
  paddingRight: 16,
  span: {
    fontSize: '18px',
  },
  p: {
    fontSize: '12px',
  },
}));

const StyledListItem = styled(ListItem)(() => ({
  backgroundColor: '#dceafd',
  paddingTop: '17px',
  paddingRight: '24px',
  paddingBottom: '17px',
  paddingLeft: '42px',
}));

const StyledPublishStoreButton = styled(Button)(() => ({
  letterSpacing: '1.25px',
  marginRight: '16px',
}));

type Props = {
  isOwner: boolean;
  handleSubmit: () => void;
  primaryText: TranslationId;
  secondaryText: TranslationId;
  tooltipMessage: TranslationId;
  buttonText: TranslationId;
};

const PublishStoreButton = ({
  isOwner,
  handleSubmit,
  primaryText,
  secondaryText,
  tooltipMessage,
  buttonText,
}: Props) => {
  const translate = useAppSelector((state) => getTranslate(state));
  return (
    <StyledListItem>
      <StyledListItemText primary={translate(primaryText)} secondary={translate(secondaryText)} />
      <Tooltip
        fdKey={'tooltip-publish-store'}
        messageId={tooltipMessage}
        disableHoverListener={!isOwner}
        disableTouchListener={!isOwner}
      >
        <div>
          <StyledPublishStoreButton
            data-fd="publish_store_btn"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={!isOwner}
          >
            {translate(buttonText)}
          </StyledPublishStoreButton>
        </div>
      </Tooltip>
    </StyledListItem>
  );
};

export default PublishStoreButton;
