import React, { useCallback, useEffect } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import { ServiceCharge } from '@flipdish/api-client-typescript';
import CircularProgress from '@mui/material/CircularProgress';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { storeActions } from '../../../actions/store.actions';
import * as storeConstants from '../../../constants/store.constants';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../../selectors/loading.selector';
import { getSelectedStore } from '../../../selectors/store.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import PageLayout from '../../../ui/Layout';
import StoreServiceChargeForm from './StoreServiceChargeForm';

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);
const initialLoadingErrorSelector = createLoadingErrorSelectorFactory([storeConstants.STORE_LOAD]);

const serviceChargeLoadingSelector = createLoadingSelector([
  storeConstants.STORE_GET_SERVICE_CHARGE,
]);

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    margin: theme.spacing(1.25),
  },
}));

type Props = ReturnType<typeof mapStateProps> & ReturnType<typeof mapDispatchToProps>;

const StoreServiceCharge = (props: Props) => {
  const classes = useStyles();
  const {
    selectedApp,
    store,
    loading,
    loadingError,
    serviceChargeLoading,
    getServiceCharge,
    updateServiceCharge,
    serviceCharge,
  } = props;

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_storeServiceCharge', {
      action: 'logged_in',
    });
  }, []);

  const { storeId } = useLoadStoreFromSalesChannelIdIntoRedux({ store });

  useEffect(() => {
    storeId && getServiceCharge(Number(storeId));
  }, [storeId]);

  const onServiceChargeChange = (newServiceCharge: ServiceCharge) => {
    updateServiceCharge(Number(storeId), newServiceCharge);
  };

  const loadingDependencies = loading || serviceChargeLoading;

  const backToParent = useCallback(({ history }: any) => {
    const parent = history.location.pathname.replace('/servicecharge', '');
    history.push(parent);
  }, []);

  if (loadingError) {
    return <Redirect to={'/' + selectedApp.AppId + '/storegroups'} />;
  }

  return (
    <PageLayout
      toParent={backToParent}
      title={<Translate id="Service_charge_title" />}
      caption={(store && store.Name) || ''}
    >
      {loadingDependencies ? (
        <CircularProgress className={classes.loader} size={50} />
      ) : (
        store && (
          <StoreServiceChargeForm
            serviceCharge={serviceCharge}
            onServiceChargeChange={onServiceChargeChange}
          />
        )
      )}
    </PageLayout>
  );
};

const mapStateProps = (state: AppState) => {
  const store = getSelectedStore(state);

  return {
    serviceCharge: state.stores.serviceCharge,
    selectedApp: state.currentApp,
    store,
    loading: initialLoadingSelector(state),
    loadingError: initialLoadingErrorSelector(state),
    serviceChargeLoading: serviceChargeLoadingSelector(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    getServiceCharge: (storeId: number) => dispatch(storeActions.getServiceCharge(storeId)),
    updateServiceCharge: debounce(
      (storeId: number, serviceCharge: ServiceCharge) =>
        dispatch(storeActions.updateServiceCharge({ storeId, serviceCharge })),
      1000
    ),
  };
};

export default connect(mapStateProps, mapDispatchToProps)(StoreServiceCharge);
